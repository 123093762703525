.apah-container {
	.cover {
		margin-bottom: 93px;

		.photo {
			width: 100%;
			img {
				width: 100%; } } }

	.video {
		margin: 0 4.27% 93px;
		position: relative;
		width: 100% - 4.27% * 2;
		height: 0;
		padding-bottom: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%; } }

	.devices {
		.photo {
			width: 100%;
			img {
				width: 100%; } } } }

@media all and (max-width: $ipad-pro) {
	.apah-container {
		.cover {
			.photo {
				img {
					width: 100%; } } } } }

@media all and (max-width: $tablet) {
	.apah-container {
		.cover {
			margin: 0 30px 98px; }

		.video {
			margin: 0 30px 113px; } } }

@media all and (max-width: $mobile) {
	.apah-container {
		.cover {
			margin: 0 13px 39px; }

		.video {
			margin: 0 13px 54px; } } }
