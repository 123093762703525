@charset "UTF-8";
/*"cwTexHei", "微軟正黑體", "Microsoft JhengHei", "PingFangTC"*/
/*@import url(//fonts.googleapis.com/earlyaccess/cwtexhei.css)* */
/*@import url(//fonts.googleapis.com/earlyaccess/cwtexyen.css)* */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,800");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,800");
@font-face {
  font-family: 'NettoOT';
  src: url("/asset/fonts/NettoOT.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url("/asset/fonts/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Amiri';
  src: url("/asset/fonts/Amiri-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

button {
  border-radius: 0px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: black;
}

@-webkit-keyframes highlight {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}

@-moz-keyframes highlight {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}

@-o-keyframes highlight {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}

@keyframes highlight {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}

body {
  overflow-x: hidden;
  padding: 10px;
  background-color: #ffdf24;
}

body .mobile-show {
  display: none;
}

body .phone-show {
  display: none;
}

body .body-container {
  background-color: white;
}

body .content-container {
  position: relative;
  padding-top: 110px;
  height: fit-content;
  min-height: calc(100vh - 211px);
}

body .loading {
  z-index: 1000000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: white;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

body .loadingout {
  opacity: 0;
  height: 0%;
  top: 100vh;
}

body .lazy-hidden {
  opacity: 0;
}

body .left {
  float: left;
}

body .right {
  float: right;
}

body img {
  -webkit-user-drag: none;
}

body .center {
  margin: 0px auto;
}

body .title-container-second-line {
  width: 280px;
  height: 22px;
  margin: 0px auto 60px;
}

body .footer-tri {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-left: 16px solid #ffdf24;
  border-bottom: 10px solid transparent;
}

body .copy-right-ch {
  text-align: center;
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 16px;
  letter-spacing: 1px;
}

body .copy-right-en {
  text-align: center;
  font-family: "NettoOT";
  margin: 50px auto;
  letter-spacing: 1px;
  font-size: 16px;
}

body .copy-right-en br {
  display: none;
}

body .hidden {
  display: none;
}

footer {
  height: 80px;
  bottom: 0px;
  width: 95vw;
  margin: 75px auto 0;
}

#footer-with-border {
  height: auto;
  overflow: auto;
}

.split-line {
  width: 75px;
  height: 1px;
  background-color: #363531;
  margin: 44px auto;
}

.reg-button {
  display: block;
  position: relative;
  margin: 20px auto;
  width: 160px;
  height: 42px;
  background-color: white;
  border: solid 3px #363531;
  text-align: center;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.reg-button:hover {
  background-color: #ffdf24;
}

.reg-button:hover .layer {
  top: 0;
}

.reg-button .layer {
  position: absolute;
  background-color: #ffdf24;
  width: inherit;
  height: inherit;
  top: -50px;
  right: -2px;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  line-height: 38px;
  text-align: center;
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.page-header {
  margin: 50px auto;
  width: fit-content;
  font-family: "NettoOT", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #363631;
}

.page-header.home-title {
  margin: 47px auto 0;
}

.page-header br {
  display: none;
}

.yellow-bg {
  position: relative;
  z-index: 0;
}

.yellow-bg:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 55%;
  margin: 0 3px;
  width: 0;
  height: 35%;
  background-color: #ffdf24;
  z-index: -1;
  transform: skew(-30deg);
  transition: 1s ease-in-out;
}

.yellow-bg.fill-bg:before {
  width: calc(100% - 6px);
}

.margin-box {
  width: 100%;
  height: 30px;
}

@media all and (max-width: 800px) {
  body {
    padding: 8px;
  }
  body .mobile-show {
    display: block;
  }
  body .mobile-not-show {
    display: none;
  }
  body .content-container {
    padding-top: 72px;
    min-height: calc(100vh - 225px);
  }
  body .copy-right-ch {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 1px;
  }
  body .copy-right-en {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    letter-spacing: 1.5px;
  }
  footer {
    margin-top: 90px;
  }
  #footer-with-border {
    line-height: 1.8;
  }
  .split-line {
    margin: 35px auto;
  }
  .page-header {
    margin: 30px auto;
  }
  .page-header.home-title {
    margin: 36px auto 0;
  }
  .page-header br {
    display: block;
  }
}

@media all and (max-width: 580px) {
  body {
    padding: 5px;
  }
  body .phone-not-show {
    display: none;
  }
  body .phone-show {
    display: block;
  }
  body .copy-right-ch {
    font-size: 12px;
  }
  body .copy-right-en {
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 1px;
    margin: 30px auto;
  }
  body .copy-right-en br {
    display: block;
  }
  body .content-container {
    padding-top: 60px;
    min-height: calc(100vh - 160px);
  }
  footer {
    margin-top: 60px;
  }
  .split-line {
    margin: 20px auto;
  }
  .reg-button {
    width: 121px;
    height: 38px;
    font-size: 14px;
    line-height: 1.71;
  }
  .page-header {
    margin: 20px auto;
    font-size: 18px;
    line-height: 1.44;
  }
  .page-header.home-title {
    margin: 40px auto 0;
  }
  .margin-box {
    height: 20px;
  }
}

.mobile-nav-top {
  display: none;
}

.desktop-nav-top {
  position: fixed;
  top: 0;
  width: calc(100% - 20px);
  height: 120px;
  border-top: solid 10px #ffdf24;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 99999;
}

.desktop-nav-top a {
  font-size: 0;
}

.desktop-nav-top .nav-container {
  margin: 0 auto;
  padding: 0 30px;
  height: 100%;
  max-width: 1260px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-nav-top .nav-logo {
  position: relative;
  height: 46px;
  width: 46px;
  top: 25px;
  left: 15%;
}

.desktop-nav-top .nav-logo-with-word {
  position: inherit;
  width: 102px;
  height: 35.3px;
}

.desktop-nav-top ul {
  margin-top: 15px;
  width: 520px;
  height: 18px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.desktop-nav-top ul li {
  float: right;
  margin: 0px 16.5px;
  text-align: center;
  position: relative;
}

.desktop-nav-top ul li .nav-dec {
  position: absolute;
  top: 10px;
  left: -15px;
  width: 0px;
  height: 4px;
  background-color: #ffdf24;
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.desktop-nav-top ul li .fb-icon {
  display: block;
  background-image: url(../../../asset/img/nav/nav-fb.svg);
  background-position: center;
  background-size: 8px 20px;
  background-repeat: no-repeat;
  width: 8px;
  height: 20px;
  transition: all .2s;
}

.desktop-nav-top ul li .fb-icon:after {
  content: "";
  display: none;
  background-image: url("../../../asset/img/nav/nav-fb-h.svg");
}

.desktop-nav-top ul li a {
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 16px;
  line-height: normal;
  letter-spacing: 1px;
  color: #363531;
  transition: .2s ease-in-out;
}

.desktop-nav-top ul li a:hover + .nav-dec, .desktop-nav-top ul li a.active + .nav-dec, .desktop-nav-top ul li a:active + .nav-dec {
  width: 10px;
  height: 3px;
  background-color: #ffdf24;
}

.desktop-nav-top ul li a.active {
  font-weight: bold;
}

.desktop-nav-top ul li:hover .fb-icon {
  background-image: url(../../../asset/img/nav/nav-fb-h.svg);
}

.desktop-nav-top ul li.facebook {
  width: 8px;
  margin-right: 0;
}

.mobile-nav-list {
  display: none;
}

@media all and (max-width: 800px) {
  .desktop-nav-top {
    display: none;
  }
  .mobile-nav-top {
    display: block;
  }
  .mobile-nav-top {
    width: calc( 100% - 16px);
    height: 80px;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.95);
    position: fixed;
    border-top: solid 8px #ffdf24;
    top: 0;
    z-index: 999;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .mobile-nav-top.open {
    background-color: transparent;
  }
  .mobile-nav-top img {
    position: fixed;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .mobile-nav-top .word {
    top: 30px;
    left: 40px;
    width: 109px;
    height: 35px;
  }
  .mobile-nav-top .word.open {
    left: -130px;
  }
  .mobile-nav-top .icon {
    top: 30px;
    left: 114px;
    width: 35px;
    height: 34px;
    transform: rotate(115deg);
    opacity: 0;
    visibility: hidden;
  }
  .mobile-nav-top .icon.open {
    left: 48px;
    width: 40px;
    height: 40px;
    transform: rotate(0deg);
    opacity: 1;
    visibility: visible;
  }
  .burger {
    width: 24px;
    height: 18px;
    position: fixed;
    right: 42px;
    top: 42px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 11;
  }
  .burger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #363531;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  .burger span:nth-child(1) {
    top: 0px;
  }
  .burger span:nth-child(2) {
    top: 8px;
    width: 80%;
  }
  .burger span:nth-child(3) {
    top: 16px;
  }
  .burger.open span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  .burger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  .burger.open span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .mobile-nav-list {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100vh;
    left: 0;
    background-color: rgba(250, 247, 245, 0.97);
    border: solid 8px #ffdf24;
    z-index: 10;
    overflow-y: scroll;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    visibility: hidden;
  }
  .mobile-nav-list.open {
    top: 0;
    visibility: visible;
  }
  .mobile-nav-list ul {
    text-align: center;
    font-size: 18px;
    letter-spacing: 1.5px;
    margin: 120px auto;
  }
  .mobile-nav-list ul li {
    margin-bottom: 35px;
    color: #363531;
  }
  .mobile-nav-list ul li img {
    width: 8px;
    height: 20px;
  }
  .mobile-nav-list ul li.facebook {
    margin-top: 20px;
  }
}

@media all and (max-width: 580px) {
  .mobile-nav-top {
    height: 65px;
    width: calc(100% - 10px);
    border-top: solid 5px #ffdf24;
  }
  .mobile-nav-top.open {
    background-color: transparent;
  }
  .mobile-nav-top .word {
    top: 18px;
    left: 18px;
    width: 87px;
    height: 30px;
  }
  .mobile-nav-top .icon {
    top: 18px;
    left: 79px;
    transform: rotate(115deg);
    width: 29px;
    height: 28px;
  }
  .mobile-nav-top .icon.open {
    top: 20px;
    left: 25px;
    width: 33px;
    height: 33px;
  }
  .burger {
    right: 18px;
    top: 25px;
  }
  .mobile-nav-list {
    border: solid 5px #ffdf24;
  }
  .mobile-nav-list ul {
    font-size: 16px;
    letter-spacing: 1.5px;
    margin: 80px auto;
  }
  .mobile-nav-list ul li {
    margin-bottom: 25px;
  }
  .mobile-nav-list ul li.facebook {
    margin-top: 30px;
  }
}

.story-content {
  width: 788px;
  height: auto;
  font-family: "NettoOT";
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 1px;
  color: #363531;
  overflow: auto;
  text-align: center;
  margin: 0 auto;
}

.story-content p {
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

body .long-title-container.about-title-slogan {
  width: 280px;
}

.brand-container {
  float: left;
  text-align: center;
  font-size: 14px;
  font-family: "NettoOT";
  letter-spacing: 1.5px;
  opacity: .75;
  -webkit-transition: 0.2s ease-in-out;
  -webkit-transition--moz-transition: .2s ease-in-out;
  -webkit-transition--o-transition: .2s ease-in-out;
  -webkit-transition-transition: .2s ease-in-out;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.brand-container:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.big-margin {
  margin: 0px 50px;
}

.mid-margin {
  margin: 5px 30px;
}

.min-margin {
  margin: 10px 1px;
}

.partners-container {
  padding-top: 10px;
  width: 878px;
  height: 100px;
}

.partners-container img {
  width: 100%;
}

.partners-container .brand-container {
  width: calc(100% / 3 - 90px);
  margin: 5px 45px;
}

.award-container {
  padding-top: 10px;
  width: 416px;
  height: 100px;
}

.award-container img {
  width: 100%;
}

.award-container .brand-container {
  width: calc(50% - 50px);
  margin: 0 25px;
}

.client-container {
  padding-bottom: 100px;
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  min-height: 300px;
  overflow: hidden;
}

.client-container img {
  width: 100%;
}

.client-container .brand-container {
  width: calc(100% / 7 - 2px);
}

@media all and (max-width: 1026px) {
  .client-container, .partners-container, .story-content {
    width: 90vw;
  }
}

@media all and (max-width: 800px) {
  .story-content {
    width: 90%;
  }
  .client-container {
    padding-bottom: 50px;
  }
  .client-container .brand-container {
    width: calc(100% / 5 - 5px);
    margin: 10px 2.5px;
  }
  .partners-container {
    width: 90vw;
  }
  .partners-container .brand-container {
    width: calc(100% / 3 - 50px);
    margin: 5px 25px;
  }
}

@media all and (max-width: 580px) {
  .client-container {
    padding-bottom: 0;
  }
  .client-container .brand-container {
    width: calc(100% / 3 - 5px);
  }
  .award-container {
    width: 90vw;
  }
  .award-container .brand-container {
    width: calc(50% - 4px);
    margin: 5px 2px;
  }
  .partners-container {
    display: flex;
    justify-content: center;
    height: auto;
  }
  .partners-container .brand-container {
    width: calc(100% / 3 - 4px);
    margin: 5px 2px;
  }
}

.service-container {
  margin: 5% auto;
  min-height: 10px;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.service-container .service-item-container {
  flex: 1;
  margin: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.service-container .service-item-container img {
  width: 130px;
  height: 130px;
  flex: 1;
}

.service-container .service-item-container .content {
  flex: 3;
}

.service-container .service-item-container .content h3 {
  height: 24px;
  font-family: "NettoOT";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

.service-container .service-item-container .content p {
  font-family: "NettoOT";
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 1px;
  margin-top: 2%;
  padding-right: 6%;
}

@media all and (max-width: 580px) {
  .service-container .service-item-container {
    flex-direction: column;
  }
  .service-container .service-item-container .content {
    text-align: center;
  }
  .service-container .service-item-container .content h3 {
    margin: 10px 0;
  }
}

.contact_h2 {
  font-size: 20px;
}

.contact-title-container {
  width: 130px;
  height: auto;
  margin: 82px auto 22px;
}

.contact-title-container .title {
  margin-bottom: 45px;
}

.contact-p {
  width: 795px;
  height: auto;
  min-height: 10px;
  overflow: hidden;
  font-family: "NettoOT";
  font-size: 16px;
  line-height: 1.93;
  letter-spacing: 1px;
  text-align: left;
  color: #363531;
}

.contact-content {
  float: left;
}

.contact-container {
  max-width: 850px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 5% auto;
}

.contact-container .contact-content {
  flex: 1;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  color: #363531;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}

.contact-container .contact-content p {
  margin-top: 5%;
}

.contact-option-container {
  font-family: "NettoOT";
  width: 795px;
  height: 50px;
}

.contact-option-container div {
  float: left;
  height: 25px;
  line-height: 25px;
  color: #363531;
  text-align: center;
  margin: 0px 36px;
}

.contact-option-container div p {
  margin: 5px;
  float: left;
  font-weight: bold;
  letter-spacing: 1px;
}

.contact-option-container div .checked {
  display: inline-block;
  float: left;
  background-color: #ffdf24;
  text-align: left;
  width: 13px;
  height: 13px;
  border-top-right-radius: 2px;
  transform: rotate(90deg) translate(13px, -14px);
  margin-right: 22px;
}

.contact-option-container div .checked:before {
  content: '';
  position: absolute;
  background-color: inherit;
  width: 13px;
  height: 13px;
  border-top-right-radius: 2px;
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
}

.contact-option-container div .checked:after {
  content: '';
  position: absolute;
  background-color: inherit;
  width: 13px;
  height: 13px;
  border-top-right-radius: 2px;
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.contact-option-container div .unchecked {
  margin: 5px;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  background-color: white;
  float: left;
}

.info_input {
  width: 795px;
  height: auto;
  overflow: auto;
}

.info_input h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #363530;
  margin-bottom: 15px;
  margin-top: 50px;
}

.info_input div {
  font-family: "NettoOT";
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  letter-spacing: 1px;
  color: #363531;
}

.info_input h1 {
  text-align: left;
  width: 60%;
  margin-left: 0;
  margin-top: 30px;
  margin-bottom: 14px;
}

.info_input .input-block {
  float: left;
  width: calc(50% - 15px);
  height: 70px;
  position: relative;
}

.info_input .input-block:nth-of-type(odd) {
  margin: 10px 15px 10px 0;
}

.info_input .input-block:nth-of-type(even) {
  margin: 10px 0 10px 15px;
}

.info_input .input-block div {
  margin-top: 2px;
}

.info_input input[type="text"], .info_input [type="tel"], .info_input [type="email"] {
  z-index: 3;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border: none;
  background-color: #faf7f5;
  font-family: "NettoOT";
  font-weight: bold;
  font-size: 20px;
  line-height: 0.95;
  letter-spacing: 1px;
  color: #363531;
  padding-right: 6px;
  padding-top: 5px;
  margin-top: 5px;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  text-align: right;
}

.info_input input[type="text"]:focus, .info_input [type="tel"]:focus, .info_input [type="email"]:focus {
  outline: 0;
}

.tell-us-more-block {
  width: 795px;
}

.tell-us-more-block.center {
  margin-top: 50px;
}

.tell-us-more-block h2 {
  font-size: 20px;
}

.tell-us-more-block div {
  font-family: "NettoOT";
  font-weight: bold;
  font-size: 16px;
  line-height: 1.95;
  letter-spacing: 1px;
  color: #363531;
  padding-left: 4px;
}

.tell-us-more-block .contact_textarea {
  display: block;
  font-family: "NettoOT";
  font-weight: bold;
  width: 100%;
  height: 283px;
  box-sizing: border-box;
  color: #363531;
  resize: none;
  border: none;
  padding: 20px;
  font-size: 20px;
  background-color: #faf7f5;
  margin: 10px auto 50px;
  letter-spacing: 1px;
}

.tell-us-more-block .contact_textarea:focus {
  outline: none;
}

.done-button {
  width: 185px;
  height: 56px;
  border-radius: 2px;
  border: solid 2px #c9c9c9;
  background-color: transparent;
  font-family: "NettoOT";
  font-size: 16px;
  font-weight: bold;
  color: #424242;
  display: block;
  margin: 50px auto 100px;
}

.type-container {
  width: 810px;
  min-height: 10px;
  overflow: hidden;
}

.radio-wrap, .checkbox-wrap {
  float: left;
  height: 30px;
  width: 240px;
  margin: 10px 20px;
}

.contact_us_table .type-container {
  display: flex;
  flex-direction: column;
}

.contact_us_table .type-container .radio-wrap, .contact_us_table .type-container .checkbox-wrap {
  padding-left: 16px;
}

.radio-wrap-large {
  position: relative;
  float: left;
  height: 30px;
  width: 400px;
  margin: 20px;
}

input[type="radio"], input[type="checkbox"] {
  display: none;
}

input[type="radio"] + label, input[type="checkbox"] + label {
  position: relative;
  display: block;
  height: auto;
  background-color: white;
  float: left;
  font-family: "NettoOT";
  font-size: 16px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 1px;
  color: #363531;
  margin-left: 10px;
}

input[type="radio"] + label .small-label, input[type="checkbox"] + label .small-label {
  width: 80px;
}

input[type="radio"] + label:before, input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  border: solid 2px #363531;
  width: 28px;
  height: 15px;
  left: -45px;
  top: 3px;
  background-color: white;
}

input[type="radio"]:checked + label, input[type="checkbox"]:checked + label {
  display: block;
  height: auto;
  float: left;
}

input[type="radio"]:checked + label:before, input[type="checkbox"]:checked + label:before {
  background-color: #ffdf24;
}

.project-table {
  width: 795px;
  margin: 0px auto;
}

.project-table h2 {
  text-align: left;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #363530;
}

.project-table h2.project-table__title-type {
  margin-bottom: 5px;
  margin-top: 50px;
}

.project-table h2.project-table__title-budget {
  margin-top: 35px;
}

.slider-bar-container {
  width: 100%;
  position: relative;
  height: 20px;
}

.slider-bar-container.center {
  margin-top: 54px;
}

.slider-bar-container div {
  position: absolute;
  font-family: "NettoOT";
  font-weight: bold;
  line-height: 1.36;
  letter-spacing: 0.9px;
  color: #363531;
}

.slider-bar-container .to-budget {
  top: -40px;
  right: 0;
}

.slider-bar-container .from-budget {
  top: -40px;
  left: 0;
}

#budget-range {
  width: calc(100% - 50px);
  background-color: rgba(96, 94, 85, 0.72);
  border: none;
  height: 2px;
  left: 25px;
}

#budget-range div {
  background-color: #ffdf24;
}

#budget-range span {
  background-color: transparent;
}

#budget-range span:active {
  outline: none;
  background-color: transparent;
}

#budget-range span:focus {
  outline: none;
  background-color: transparent;
}

#budget-range span:hover {
  outline: none;
  background-color: transparent;
}

#budget-range span:nth-child(2) {
  top: -11px;
  margin-left: -25px;
  height: 0;
  width: 0;
  border-width: 12px 0 12px 25px;
  border-color: transparent transparent transparent black;
}

#budget-range span:nth-child(3) {
  top: -11px;
  margin-left: 0;
  height: 0;
  width: 0;
  border-width: 12px 25px 12px 0px;
  border-color: transparent black transparent transparent;
}

@media all and (max-width: 800px) {
  .contact-container {
    width: 90vw;
  }
  .contact-container .contact-content {
    margin: 30px 0;
  }
  .contact-p {
    width: 90vw;
  }
  .contact-option-container {
    width: 90vw;
  }
  .contact_us_table {
    width: 90vw;
    margin: 0px auto;
  }
  .info_input {
    width: 100%;
    overflow: hidden;
    min-height: 10px;
  }
  .info_input div {
    padding-left: 0px;
  }
  .info_input h1 {
    margin: 30px 0 15px;
  }
  .info_input h2 {
    margin-bottom: 15px;
  }
  .info_input .input-block {
    width: 46%;
    float: left;
  }
  .info_input .input-block:nth-child(2n) {
    margin: 10px 6% 10px 0px;
  }
  .info_input .input-block:nth-child(2n-1) {
    margin: 10px 0;
  }
  .info_input .input-block div {
    margin-top: 2px;
  }
  .info_input input[type="text"], .info_input [type="tel"], .info_input [type="email"] {
    z-index: 3;
    width: 100%;
  }
  .tell-us-more-block {
    width: 100%;
  }
  .radio-wrap, .checkbox-wrap {
    margin: 20px 0px 20px 10px;
  }
  .project-table {
    width: 90vw;
  }
  .project-table h1 {
    width: 100%;
  }
  .type-container {
    width: 100%;
    padding-left: 30px;
  }
}

@media all and (max-width: 580px) {
  .contact-container {
    flex-direction: column;
    height: auto;
  }
  .contact-container .contact-content {
    font-size: 16px;
    letter-spacing: 0.9px;
  }
  .contact-p {
    margin-bottom: 40px !important;
  }
  .radio-wrap, .checkbox-wrap {
    margin: 0 36px 15px !important;
  }
  .type-container {
    padding-left: 0;
  }
  .info_input .input-block {
    width: 100%;
  }
  .slider-bar-container .to-budget {
    top: 25px;
  }
}

.works-nav-container {
  max-width: 1240px;
  margin: 50px auto 35px;
  height: 22px;
  box-sizing: border-box;
}

.works-nav {
  position: relative;
  float: left;
  font-family: "NettoOT";
  font-size: 18px;
  letter-spacing: 1.8px;
  color: #363531;
  margin: 0 40px 0;
  cursor: pointer;
}

.works-nav:hover > .nav-dec, .works-nav.active-works-nav > .nav-dec {
  width: 10px;
  height: 4px;
  background-color: #ffdf24;
}

.nav-dec {
  width: 0px;
  height: 4px;
  position: absolute;
  top: 8px;
  left: -15px;
  background-color: #ffdf24;
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
}

.active-works-nav {
  font-weight: bold;
}

.works-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 1230px;
  margin: 0 auto;
  overflow: auto;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
}

.works {
  width: calc(25% - 30px);
  height: calc(25% + 43px);
  margin: 15px;
}

.works div {
  float: left;
}

.works .description {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.works .description img {
  width: 50px;
  align-self: flex-start;
}

.works .description .content {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-wrap: break-word;
}

.works .description .content .work-subtitle {
  width: 100%;
}

.works .img-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.works .img-container:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.works .work-photo {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  -webkit-transition: .4s ease-in-out;
  -moz-transition: .4s ease-in-out;
  -o-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
}

.works .work-photo:hover {
  width: 104%;
  left: -2%;
  top: -2%;
}

.works .work-icon {
  float: left;
  margin: 10px 10px 0 0;
}

.works .work-title {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: .8px;
  color: #363531;
  margin: 15px 0 5px 0;
}

.works .work-subtitle {
  font-size: 16px;
  line-height: 1.36;
  letter-spacing: 0.9px;
  color: #363531;
  overflow: hidden;
}

.project-container {
  display: flex;
  max-width: 1260px;
  margin: 40px auto 0;
  padding: 0 30px;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
}

.project-container .describe {
  width: 25.5%;
  margin: 0 2% 0 0;
  box-sizing: border-box;
}

.project-container .describe h3 {
  margin-bottom: 30px;
  font-family: "NettoOT", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 30px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1.3px;
  white-space: pre-wrap;
  color: #575757;
}

.project-container .describe .block:nth-child(2), .project-container .describe .block:nth-child(3) {
  margin-bottom: 17px;
}

.project-container .describe .block:nth-child(4) {
  margin-bottom: 32px;
}

.project-container .describe .block h4 {
  font-family: "NettoOT", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 3px;
  color: #575757;
}

.project-container .describe .block p {
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 1px;
  color: #575757;
  white-space: pre-wrap;
}

.project-container .describe .content p {
  font-family: "NettoOT", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 1px;
  color: #575757;
  white-space: normal;
}

.project-container .design {
  width: 74.5%;
  box-sizing: border-box;
}

@media all and (max-width: 1210px) {
  .work-title {
    width: 74%;
  }
  .work-subtitle {
    width: 74%;
  }
}

@media all and (max-width: 1026px) {
  .works-nav-container {
    width: calc(100vw - 50px);
  }
  .works-wrap {
    width: calc(90vw + 30px);
  }
  .works .work-photo:hover {
    width: 100%;
  }
}

@media all and (max-width: 800px) {
  .works-nav-container {
    width: initial;
    margin: 35px 10px 10px;
  }
  .works-wrap {
    width: initial;
    margin: 0 17px;
  }
  .works {
    width: calc(33.33% - 20px);
    height: calc(33.33% - 43px);
    margin: 20px 10px;
  }
  .works .work-photo {
    height: auto;
  }
  .works .work-photo:hover {
    left: 0;
    top: 0;
  }
  .project-container {
    flex-direction: column;
    margin-top: 20px;
    padding: 0;
  }
  .project-container .describe {
    margin: 0 36px;
    width: initial;
    padding: 0;
  }
  .project-container .describe h3 {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: normal;
  }
  .project-container .describe .block:nth-child(2), .project-container .describe .block:nth-child(3) {
    margin-bottom: 6px;
  }
  .project-container .describe .block:nth-child(4) {
    margin-bottom: 13px;
  }
  .project-container .describe .block h4, .project-container .describe .block p {
    display: inline-block;
    font-size: 18px;
    line-height: 1.56;
  }
  .project-container .describe .block h4 {
    width: 95px;
  }
  .project-container .describe .content p {
    font-size: 18px;
    line-height: 1.56;
  }
  .project-container .design {
    margin-top: 46px;
    width: initial;
  }
}

@media all and (max-width: 580px) {
  .works-nav-container {
    margin: 30px 20px 10px;
  }
  .works-nav-container .works-nav {
    font-size: 14px;
    margin: 0 40px 0 0;
  }
  .works-wrap {
    margin: 0 14px;
  }
  .works {
    width: calc(50% - 12px);
    height: calc(50% - 43px);
    margin: 20px 6px;
  }
  .project-container .describe {
    margin: 0 15px;
  }
  .project-container .describe .block {
    white-space: normal;
  }
  .project-container .describe .block:nth-child(2), .project-container .describe .block:nth-child(3) {
    margin-bottom: 3px;
  }
  .project-container .describe .block:nth-child(4) {
    margin-bottom: 15px;
  }
  .project-container .describe .block h4, .project-container .describe .block p {
    font-size: 16px;
    line-height: 1.75;
  }
  .project-container .describe .block h4 {
    width: 98px;
  }
  .project-container .describe .content p {
    font-size: 16px;
    line-height: 1.75;
  }
  .project-container .design {
    margin-top: 36px;
  }
}

.banner-container {
  margin: 0 auto 0;
  position: relative;
  width: 100%;
  height: auto;
}

.banner-container .slick-slider {
  margin-bottom: 0;
}

.banner-container .slick-wrap {
  width: 100%;
}

.banner-container .slick-wrap .slick-list {
  width: 100%;
}

.banner-container .slick-wrap .slick-dots {
  bottom: 10px;
  z-index: 1;
}

.banner-container .slick-wrap .slick-item {
  position: relative;
  width: 100%;
}

.banner-container .slick-wrap .slick-item .desktop-view {
  display: block;
}

.banner-container .slick-wrap .slick-item .tablet-view, .banner-container .slick-wrap .slick-item .mobile-view {
  display: none;
}

.banner-container .slick-wrap .slick-item img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.banner-container .slick-wrap .slick-item .content {
  position: absolute;
  display: block;
  width: 30%;
  z-index: 10;
}

.banner-container .slick-wrap .slick-item .content .banner-title {
  font-family: "NettoOT";
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}

.banner-container .slick-wrap .slick-item .content p {
  font-family: "NettoOT";
  font-size: 18px;
  line-height: 1.44;
  letter-spacing: 1px;
  text-align: left;
  margin: 4% 0;
}

.banner-container .slick-wrap .slick-item .content a {
  display: inline-block;
  font-family: "NettoOT";
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: solid 1px;
  padding: 8px 28px;
  margin-top: 2%;
  border-radius: 2px;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.banner-container .slick-wrap .slick-item .content.foolishfarm {
  top: 27%;
  right: 5%;
  width: 33%;
}

.banner-container .slick-wrap .slick-item .content.foolishfarm .banner-title {
  color: #004405;
}

.banner-container .slick-wrap .slick-item .content.foolishfarm p {
  color: #363531;
}

.banner-container .slick-wrap .slick-item .content.foolishfarm a {
  border-color: #004500;
  color: #004500;
}

.banner-container .slick-wrap .slick-item .content.foolishfarm a:hover {
  background-color: #004405;
  color: white;
}

.banner-container .slick-wrap .slick-item .content.ekitt {
  top: 28%;
  left: 31%;
}

.banner-container .slick-wrap .slick-item .content.ekitt .banner-title {
  color: #58585b;
}

.banner-container .slick-wrap .slick-item .content.ekitt p {
  color: #58585b;
}

.banner-container .slick-wrap .slick-item .content.ekitt a {
  border-color: #58585b;
  color: #58585b;
}

.banner-container .slick-wrap .slick-item .content.ekitt a:hover {
  background-color: #3c3c3c;
  color: white;
}

.banner-container .slick-wrap .slick-item .content.innovex {
  top: 27%;
  right: 18%;
}

.banner-container .slick-wrap .slick-item .content.innovex .banner-title {
  color: #fdcc1a;
}

.banner-container .slick-wrap .slick-item .content.innovex p {
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(25, 25, 25, 0.51);
}

.banner-container .slick-wrap .slick-item .content.innovex a {
  border-color: #ffffff;
  color: #ffffff;
}

.banner-container .slick-wrap .slick-item .content.innovex a:hover {
  background-color: #ffdf24;
  color: #3c3c3c;
  border-color: #ffdf24;
}

.banner-container .slick-wrap .slick-item .content.laravelconf {
  top: 23%;
  right: 11%;
}

.banner-container .slick-wrap .slick-item .content.laravelconf .banner-title {
  color: #ee6c63;
}

.banner-container .slick-wrap .slick-item .content.laravelconf p {
  color: #4a4a4a;
}

.banner-container .slick-wrap .slick-item .content.laravelconf a {
  border-color: #ee6c63;
  color: #ee6c63;
}

.banner-container .slick-wrap .slick-item .content.laravelconf a:hover {
  background-color: #ee6c63;
  color: white;
}

.center-p {
  max-width: 996px;
  width: 90vw;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 1px;
  text-align: center;
  font-family: "NettoOT";
  color: #363531;
}

.home-service-container {
  width: 850px;
  min-height: 20px;
  margin: 60px auto 20px;
  display: flex;
}

.home-service-container .home-service-item-container {
  flex: 1;
  width: 33.33%;
  text-align: center;
}

.home-service-container .home-service-item-container p {
  font-family: "NettoOT";
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #363530;
  text-align: center;
  white-space: nowrap;
  margin-top: 5%;
}

.short-service {
  width: 120px;
}

.home-project-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1230px;
  margin: 0 auto;
  overflow: auto;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
}

.home-project-container .works {
  align-self: flex-start;
}

.footer-info-container {
  height: 200px;
  display: block;
}

.footer-info-container .logo-info {
  margin: -13px 30px 0px 100px;
  line-height: 46px;
  font-size: 18px;
  letter-spacing: 1.3px;
  color: #363531;
}

.footer-info-container .datail-info {
  width: 600px;
}

.footer-info-container .datail-info p {
  margin: 0px 10px 10px;
  line-height: 20px;
}

.footer-info-container .datail-info img {
  margin: 0px 10px;
  width: 20px;
  height: 20px;
}

@media all and (max-width: 1026px) {
  .banner-container .slick-wrap .slick-item .content .banner-title {
    font-size: 28px;
  }
  .banner-container .slick-wrap .slick-item .content p {
    font-size: 16px;
    letter-spacing: 1px;
    margin: 3% 0;
  }
  .banner-container .slick-wrap .slick-item .content a {
    display: none;
  }
  .banner-container .slick-wrap .slick-item .content.foolishfarm {
    top: 23%;
    right: 5%;
    width: 33%;
  }
  .banner-container .slick-wrap .slick-item .content.ekitt {
    top: 25%;
    left: 31%;
    width: 30%;
  }
  .banner-container .slick-wrap .slick-item .content.innovex {
    top: 22%;
    right: 12%;
  }
  .banner-container .slick-wrap .slick-item .content.laravelconf {
    top: 25%;
  }
  .home-project-container {
    width: calc(90vw + 30px);
  }
}

@media all and (max-width: 800px) {
  .banner-container .slick-wrap .slick-item .tablet-view {
    display: block;
  }
  .banner-container .slick-wrap .slick-item .desktop-view, .banner-container .slick-wrap .slick-item .mobile-view {
    display: none;
  }
  .banner-container .slick-wrap .slick-item .content .banner-title {
    font-size: 26px;
  }
  .banner-container .slick-wrap .slick-item .content p {
    font-size: 14px;
    letter-spacing: .8px;
    margin: 3% 0 0 0;
  }
  .banner-container .slick-wrap .slick-item .content a {
    display: none;
  }
  .banner-container .slick-wrap .slick-item .content.foolishfarm {
    top: 33%;
    right: 6%;
    width: 30%;
  }
  .banner-container .slick-wrap .slick-item .content.ekitt {
    top: 21%;
    left: 40%;
    width: 39%;
  }
  .banner-container .slick-wrap .slick-item .content.innovex {
    top: 24%;
    right: 10%;
    width: 35%;
  }
  .banner-container .slick-wrap .slick-item .content.laravelconf {
    top: 25%;
    right: 13%;
  }
  .home-service-container {
    width: 90vw;
  }
  .home-service-container .home-service-item-container p {
    font-size: 18px;
  }
  .home-service-container .home-service-item-container p span {
    display: block;
    margin: 1% 0;
  }
  .home-project-container {
    width: initial;
    margin: 0 17px;
  }
  .center-p {
    max-width: 627px;
  }
}

@media all and (max-width: 580px) {
  .banner-container .slick-wrap .slick-item .mobile-view {
    display: block;
  }
  .banner-container .slick-wrap .slick-item .tablet-view, .banner-container .slick-wrap .slick-item .desktop-view {
    display: none;
  }
  .banner-container .slick-wrap .slick-item .content .banner-title {
    font-size: 21px;
    text-align: center;
  }
  .banner-container .slick-wrap .slick-item .content p {
    display: none;
  }
  .banner-container .slick-wrap .slick-item .content a {
    display: none;
  }
  .banner-container .slick-wrap .slick-item .content.foolishfarm, .banner-container .slick-wrap .slick-item .content.ekitt, .banner-container .slick-wrap .slick-item .content.innovex, .banner-container .slick-wrap .slick-item .content.laravelconf {
    top: initial;
    left: initial;
    right: initial;
    bottom: 10vw;
    width: 100%;
  }
  .home-service-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .home-service-container .home-service-item-container {
    width: 100%;
    margin: 5% 0;
  }
  .home-service-container .home-service-item-container p {
    font-size: 16px;
  }
  .home-service-container .home-service-item-container p span {
    display: inline;
  }
  .center-p {
    line-height: 1.57;
  }
  .home-project-container {
    margin: 0 14px;
  }
}

.recruit-info-wrap {
  float: left;
  display: auto;
  width: 700px;
  margin: 100px 0px 0px 130px;
  min-height: 100px;
  overflow: hidden;
}

.recruit-info-wrap .title-container {
  margin: 30px 5px -30px;
}

.recruit-info-wrap h2 {
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 8px;
  color: #363531;
}

.recruit-info-wrap ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.8px;
}

.recruit-form-wrap {
  min-height: 100px;
  overflow: hidden;
}

.recruit-form-wrap .job-apply-title {
  margin: 85px 0px 25px 45px;
  width: 120px;
}

.recruit-form-wrap .recruit-form {
  float: right;
  margin-right: 120px;
  width: 420px;
  height: auto;
  overflow: auto;
  padding: 30px;
  border: solid 5px #faf7f5;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.72);
  box-shadow: 0 2px 3px 0 rgba(64, 64, 64, 0.14);
  overflow-x: hidden;
  margin-bottom: 50px;
}

.recruit-form-wrap .recruit-form h2 {
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 8px;
  color: #363531;
}

.recruit-form-wrap .recruit-form h3 {
  font-family: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #363531;
  margin: 15px 0px 10px;
}

.recruit-form-wrap .recruit-form input[type=text], .recruit-form-wrap .recruit-form [type=tel], .recruit-form-wrap .recruit-form [type=email] {
  width: 410px;
  height: 40px;
  padding: 0px 5px;
  background-color: #faf7f5;
  font-size: 20px;
  border: none;
  text-align: right;
}

.recruit-form-wrap .recruit-form input[type=text]:hover, .recruit-form-wrap .recruit-form [type=tel]:hover, .recruit-form-wrap .recruit-form [type=email]:hover {
  border: none;
}

.recruit-form-wrap .recruit-form input[type=text]:active, .recruit-form-wrap .recruit-form [type=tel]:active, .recruit-form-wrap .recruit-form [type=email]:active {
  border: none;
}

.recruit-form-wrap .recruit-form input[type=text]:focus, .recruit-form-wrap .recruit-form [type=tel]:focus, .recruit-form-wrap .recruit-form [type=email]:focus {
  outline: 0;
}

.recruit-form-wrap .recruit-form .selection-wrap {
  width: 500px;
}

.recruit-form-wrap .recruit-form .single-selection {
  float: left;
  position: relative;
}

.recruit-form-wrap .recruit-form .single-selection:after {
  top: 24px;
  right: 34px;
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent #363531 transparent;
}

.recruit-form-wrap .recruit-form select {
  float: left;
  width: 120px;
  height: 40px;
  margin: 0px 30px 0px 0px;
  background-color: #faf7f5;
  padding: 10px;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: none;
}

.recruit-form-wrap .recruit-form .radio-outer-wrap {
  width: 460px;
  height: 40px;
  margin: 18px auto;
}

.recruit-form-wrap .recruit-form .small-radio-wrap {
  width: 90px;
  float: left;
  margin: 0px 12px;
}

.recruit-form-wrap .recruit-form input[type="radio"] + label {
  width: 90px;
  font-size: 14px;
  margin-left: 34px;
  font-weight: normal;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
}

.recruit-form-wrap .recruit-form textarea {
  display: block;
  font-family: "NettoOT";
  font-weight: bold;
  width: 400px;
  height: 291px;
  color: rgba(96, 94, 85, 0.72);
  resize: none;
  border: none;
  padding: 10px;
  background-color: #faf7f5;
  margin: 10px auto;
  letter-spacing: 0.8px;
}

.recruit-form-wrap .recruit-form textarea:focus {
  outline: none;
}

.recruit-form-wrap .recruit-form .reg-button {
  width: 145px;
  height: 42px;
}

.recruit-cover-info {
  margin: 5% auto;
  text-align: left;
}

.recruit-type-container {
  display: flex;
  max-width: 850px;
  min-height: 100px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0px auto;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
  letter-spacing: 1.3px;
  color: #363531;
  font-size: 20px;
  font-weight: bold;
}

.recruit-type-container .recruit-title-container {
  align-self: center;
  flex: 2;
}

.recruit-type-container .reg-button {
  align-self: center;
}

.recruit-type-container p {
  margin: 3px 0px;
  font-family: "NettoOT", "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei";
}

@media all and (max-width: 1026px) {
  .recruit-type-container, .recruit-cover-info {
    width: 90vw;
  }
}

@media all and (max-width: 580px) {
  .recruit-type-container {
    background-color: #faf7f5;
    margin-top: 3%;
  }
  .recruit-type-container .recruit-title-container {
    margin: 6%;
    min-width: 55%;
  }
  .recruit-type-container .recruit-title-container p {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  .recruit-type-container button.reg-button {
    padding: 0;
    margin: 20px 20px 20px 0;
    width: 66px;
    height: 35px;
    font-size: 14px;
    border: solid 2px #363631;
    text-align: center;
    box-sizing: border-box;
  }
}

.design.innovex {
  margin: 0;
}

.innovex-container {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center 38.52%;
  background-repeat: no-repeat;
}

.innovex-container .desktop-block {
  height: 43.67%;
  margin: 0 auto;
  transform: translate(0);
}

.innovex-container .desktop-block .video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.innovex-container .desktop-block .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.innovex-container .desktop-block .video img {
  width: 132%;
  position: absolute;
  left: -16%;
  top: -17%;
  object-fit: contain;
}

.innovex-container .info-block {
  padding: 0 9.63% 0 9.97%;
  margin-bottom: 10.8%;
  margin-top: 5%;
}

.innovex-container .info-block h3, .innovex-container .info-block p {
  font-family: "OpenSans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  color: #4c4c4c;
}

.innovex-container .info-block h3 {
  margin-bottom: 2.46%;
  font-size: 30px;
  letter-spacing: 0px;
  font-style: italic;
  font-weight: 600;
}

.innovex-container .info-block p {
  font-size: 15px;
  line-height: 1.87;
  letter-spacing: 0.5px;
  white-space: normal;
}

.innovex-container .info-block img.match {
  margin: 0 -2.2%;
  width: 104.4%;
  height: auto;
}

.innovex-container .info-block .container {
  position: relative;
  width: 100%;
  height: 34.04vw;
  max-height: 388px;
  margin-top: 4.18%;
}

.innovex-container .info-block .container .attendees, .innovex-container .info-block .container .media, .innovex-container .info-block .container .exhibitor {
  position: absolute;
  width: 69.64%;
}

.innovex-container .info-block .container .attendees label, .innovex-container .info-block .container .media label, .innovex-container .info-block .container .exhibitor label {
  display: block;
  margin-bottom: 0.97%;
  line-height: 41px;
  font-family: "OpenSans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0px;
}

.innovex-container .info-block .container .attendees img, .innovex-container .info-block .container .media img, .innovex-container .info-block .container .exhibitor img {
  width: 100%;
  object-fit: contain;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
}

.innovex-container .info-block .container .attendees label {
  color: #251c99;
}

.innovex-container .info-block .container .media {
  right: 0;
}

.innovex-container .info-block .container .media label {
  color: #fdcc1a;
}

.innovex-container .info-block .container .exhibitor label {
  color: #e0007a;
}

.innovex-container .info-block .matching-img {
  width: calc(100% + 4.74%);
  margin: 3.06% -2.37% 0;
}

.innovex-container .device-block {
  height: 800px;
}

.innovex-container .device-block h3 {
  font-family: "OpenSans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 30px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0;
  color: #4c4c4c;
  margin-left: 7.8%;
}

.innovex-container .device-block .container__device-slick {
  position: relative;
  width: 100%;
  height: 630px;
  box-sizing: border-box;
}

.innovex-container .device-block .container__device-slick .tablet {
  position: absolute;
  width: 408px;
  height: 578px;
  bottom: 0;
  left: 22%;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-img {
  position: absolute;
  width: 408px;
  height: 578px;
  object-fit: contain;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel {
  position: absolute;
  width: 332px;
  height: 440px;
  top: 62px;
  left: 41px;
  max-width: 357px;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel img {
  width: 330px;
  height: 440px;
  object-fit: contain;
  outline: none;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots {
  bottom: -120px;
  left: 50%;
  transform: translateX(-25%);
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots li {
  margin: 0;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots li button:before {
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #cdcdcd;
  opacity: 1;
  transition: all .2s;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots li button:hover:before {
  width: 11px;
  height: 11px;
}

.innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots li.slick-active button:before {
  background-color: #242532;
  opacity: 1;
}

.innovex-container .device-block .container__device-slick .mobile {
  position: absolute;
  width: 189px;
  height: 383px;
  left: 57.2%;
  bottom: 0;
}

.innovex-container .device-block .container__device-slick .mobile .mobile-img {
  position: absolute;
  width: 189px;
  height: 383px;
  object-fit: contain;
}

.innovex-container .device-block .container__device-slick .mobile .mobile-carousel {
  position: absolute;
  width: 156.5px;
  height: 274px;
  top: 49px;
  left: 17px;
}

.innovex-container .device-block .container__device-slick .mobile .mobile-carousel img {
  width: 155px;
  height: 274px;
  object-fit: contain;
  outline: none;
}

@media all and (max-width: 1260px) {
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots {
    bottom: -120px;
    left: 50%;
    transform: translateX(-32%);
  }
}

@media all and (max-width: 1026px) {
  .project-container .design.innovex {
    margin: 50px 0 0 0;
  }
  .project-container .design .innovex-container .info-block .container {
    height: 45vw;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots {
    transform: translateX(-26%);
  }
}

@media all and (max-width: 800px) {
  .project-container .design .innovex-container .info-block {
    padding: 0 52px;
  }
  .project-container .design .innovex-container .info-block h3 {
    font-size: 30px;
    font-weight: 600;
  }
  .project-container .design .innovex-container .info-block p {
    font-size: 18px;
    line-height: 1.56;
  }
  .project-container .design .innovex-container .info-block .container .attendees label, .project-container .design .innovex-container .info-block .container .media label, .project-container .design .innovex-container .info-block .container .exhibitor label {
    font-size: 20px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet {
    left: 18.1%;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots {
    transform: translateX(-31%);
  }
  .project-container .design .innovex-container .device-block .container__device-slick .mobile {
    left: 56.8%;
  }
}

@media all and (max-width: 580px) {
  .project-container .design .innovex-container .info-block {
    margin-top: 55px;
    padding: 0 13px;
  }
  .project-container .design .innovex-container .info-block h3 {
    font-size: 24px;
    line-height: 1.12;
    letter-spacing: initial;
    font-weight: 400;
    font-style: normal;
  }
  .project-container .design .innovex-container .info-block p {
    font-size: 16px;
    letter-spacing: initial;
    line-height: 1.75;
  }
  .project-container .design .innovex-container .info-block .container {
    margin-top: 20px;
    height: auto;
  }
  .project-container .design .innovex-container .info-block .container .attendees, .project-container .design .innovex-container .info-block .container .media, .project-container .design .innovex-container .info-block .container .exhibitor {
    position: relative;
    width: 100%;
  }
  .project-container .design .innovex-container .info-block .container .attendees label, .project-container .design .innovex-container .info-block .container .media label, .project-container .design .innovex-container .info-block .container .exhibitor label {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 5px;
  }
  .project-container .design .innovex-container .device-block {
    height: 500px;
  }
  .project-container .design .innovex-container .device-block h3 {
    font-size: 24px;
    line-height: 1.12;
    letter-spacing: initial;
    font-weight: 400;
    font-style: normal;
  }
  .project-container .design .innovex-container .device-block .container__device-slick {
    height: 400px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet {
    width: 258.4px;
    height: 365.3px;
    left: 10.4%;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-img {
    width: 258.4px;
    height: 365.3px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-carousel {
    width: 211px;
    height: 300px;
    top: 28px;
    left: 26px;
    max-width: 357px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-carousel img {
    width: 226.7px;
    height: 300px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .tablet .tablet-carousel .slick-dots {
    bottom: -80px;
    left: 50%;
    transform: translateX(-35%);
  }
  .project-container .design .innovex-container .device-block .container__device-slick .mobile {
    width: 117.8px;
    height: 242px;
    left: 61.1%;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .mobile .mobile-img {
    width: 117.8px;
    height: 242px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .mobile .mobile-carousel {
    width: 98px;
    height: 182px;
    top: 27px;
    left: 10px;
  }
  .project-container .design .innovex-container .device-block .container__device-slick .mobile .mobile-carousel img {
    width: 104px;
    height: 183.4px;
  }
}

.ekitt-container {
  font-family: "NettoOT";
  color: #363531;
  white-space: normal;
  width: unset;
}

.ekitt-container section {
  width: 100%;
  height: auto;
  display: flex;
}

.ekitt-container section.photo {
  flex: 1;
  padding: 0 2%;
}

.ekitt-container section.photo img {
  width: 100%;
}

.ekitt-container section .content {
  flex: 1;
  box-sizing: border-box;
}

.ekitt-container section .content h3 {
  font-size: 30px;
  text-align: left;
  margin-bottom: 15px;
  margin-top: 4%;
}

.ekitt-container section .content p {
  flex: 1;
  font-size: 18px;
  line-height: 1.56;
}

.ekitt-container h2 {
  color: #48c0cb;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 15px;
}

.ekitt-container .line {
  background-color: #48c0cb;
  width: 50px;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
}

.ekitt-container .cover .photo {
  padding: 0 2%;
}

.ekitt-container .cover .photo img {
  width: 100%;
  height: auto;
}

.ekitt-container .cover .content {
  width: 26.3%;
  height: auto;
  padding-top: 16%;
  margin-left: 26px;
}

.ekitt-container .cover .content h1 {
  margin-top: 0;
  text-align: left;
  margin-top: 24px;
  font-size: 30px;
}

.ekitt-container .cover .content p {
  text-align: left;
  margin-top: 14px;
  font-size: 18px;
  line-height: 1.56;
}

.ekitt-container .cover .content .logo {
  width: 60px;
  height: 60px;
  margin-top: 20px;
}

.ekitt-container .cover .content .apps {
  float: left;
  margin-top: 30px;
}

.ekitt-container .cover .content .apps img {
  width: 49%;
  height: auto;
}

.ekitt-container .intro {
  margin: 10px auto 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
}

.ekitt-container .intro .photo {
  width: 100%;
}

.ekitt-container .intro .photo img {
  width: 100%;
}

.ekitt-container .intro .content {
  display: flex;
}

.ekitt-container .intro .content p {
  flex: 1;
  padding: 5px 45px;
}

.ekitt-container .feature-1 .photo, .ekitt-container .feature-1 .content, .ekitt-container .feature-2 .photo, .ekitt-container .feature-2 .content, .ekitt-container .feature-3 .photo, .ekitt-container .feature-3 .content {
  flex: 1;
  width: 50%;
}

.ekitt-container .feature-1 .photo img, .ekitt-container .feature-1 .content img, .ekitt-container .feature-2 .photo img, .ekitt-container .feature-2 .content img, .ekitt-container .feature-3 .photo img, .ekitt-container .feature-3 .content img {
  width: 100%;
}

.ekitt-container .feature-1 {
  margin: 2% 0;
}

.ekitt-container .feature-1 .content {
  margin: 15% 0 0 5%;
}

.ekitt-container .feature-1 .content p {
  flex: 1;
}

.ekitt-container .feature-2 {
  margin: 10% 0;
  position: relative;
}

.ekitt-container .feature-2 .photo img {
  margin-left: 18px;
}

.ekitt-container .feature-2 .content {
  position: absolute;
  width: 380px;
  left: 52%;
  top: 12%;
}

.ekitt-container .feature-3 .photo {
  order: 2;
}

.ekitt-container .feature-3 .content {
  padding: 125px 10% 0;
}

.ekitt-container .feature-3 .content br {
  display: none;
}

.ekitt-container .ekitt-demo .demo-pic {
  width: 100%;
  margin-top: 8%;
}

.ekitt-container .ekitt-demo .demo-pic img {
  width: 100%;
}

@media all and (max-width: 1026px) {
  .ekitt-container .cover .photo img {
    width: 100%;
    height: auto;
  }
  .ekitt-container h2 {
    margin-top: 100px;
  }
  .ekitt-container .intro {
    max-width: 100%;
  }
  .ekitt-container .intro .content p {
    padding: 5px 30px;
    line-height: 1.75;
  }
  .ekitt-container .feature-1 .photo img, .ekitt-container .feature-2 .photo img, .ekitt-container .feature-3 .photo img {
    width: 100%;
    height: auto;
  }
  .ekitt-container .feature-1 .content p, .ekitt-container .feature-2 .content p, .ekitt-container .feature-3 .content p {
    line-height: 1.75;
  }
  .ekitt-container .feature-2 .photo img {
    margin-left: 0;
  }
  .ekitt-container .feature-3 .content {
    margin: 80px 0 0 25px;
    padding: 0;
  }
  .ekitt-container .feature-3 .content p {
    padding-right: 50px;
  }
}

@media all and (max-width: 800px) {
  .ekitt-container {
    margin: 46px 32px 0;
  }
  .ekitt-container .cover .photo {
    min-width: 250px;
    max-width: 350;
    flex: 1;
  }
  .ekitt-container .cover .content {
    min-width: 250px;
    flex: 1;
    padding-top: 7%;
  }
  .ekitt-container .cover .content .apps {
    margin-top: 20%;
  }
  .ekitt-container .feature-1 .photo, .ekitt-container .feature-1 .content, .ekitt-container .feature-2 .photo, .ekitt-container .feature-2 .content, .ekitt-container .feature-3 .photo, .ekitt-container .feature-3 .content {
    flex: 1;
  }
  .ekitt-container .feature-1 .content {
    width: 50%;
    margin: 10% 0 0 5%;
  }
  .ekitt-container .feature-1 .content p {
    width: 100%;
  }
  .ekitt-container .feature-2 .photo {
    margin-top: 7%;
  }
  .ekitt-container .feature-2 .content {
    top: 0;
    right: -5%;
    max-width: 45vw;
  }
  .ekitt-container .feature-2 .content p {
    width: 90%;
  }
  .ekitt-container .feature-3 .content br {
    display: block;
  }
}

@media all and (max-width: 580px) {
  .ekitt-container {
    margin: 46px 15px 0;
  }
  .ekitt-container h2 {
    margin-top: 50px;
  }
  .ekitt-container section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .ekitt-container section .content p {
    font-size: 16px;
  }
  .ekitt-container .cover .photo img {
    width: 100%;
    height: auto;
  }
  .ekitt-container .cover .content {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .ekitt-container .cover .content .logo {
    margin-top: 0;
  }
  .ekitt-container .cover .content h3 {
    text-align: center;
  }
  .ekitt-container .cover .content p {
    text-align: left;
    font-size: 16px;
  }
  .ekitt-container .cover .content .apps {
    float: none;
    margin-top: 20px;
  }
  .ekitt-container .cover .content .apps img {
    width: 97.5px;
    height: 30px;
  }
  .ekitt-container .intro {
    max-width: 100%;
  }
  .ekitt-container .intro .content {
    margin-top: 10px;
    flex-direction: column;
  }
  .ekitt-container .intro .content p {
    padding: 0;
    margin-bottom: 15px;
  }
  .ekitt-container .feature-1 .photo {
    width: 100%;
  }
  .ekitt-container .feature-1 .photo img {
    margin-bottom: 5%;
  }
  .ekitt-container .feature-1 .content {
    margin: 0;
    width: 100%;
    padding: 0 2%;
  }
  .ekitt-container .feature-1 .content p {
    width: auto;
  }
  .ekitt-container .feature-2 {
    position: static;
  }
  .ekitt-container .feature-2 .photo {
    width: 100%;
  }
  .ekitt-container .feature-2 .content {
    position: static;
    margin: 0;
    width: 100%;
    max-width: unset;
    padding: 0 2%;
  }
  .ekitt-container .feature-2 .content p {
    width: 100%;
  }
  .ekitt-container .feature-3 .photo {
    order: 1;
    width: 100%;
  }
  .ekitt-container .feature-3 .photo img {
    max-width: 480px;
    margin-bottom: 10%;
  }
  .ekitt-container .feature-3 .content {
    margin: 0;
    order: 2;
    width: 100%;
    padding: 0 2%;
  }
  .ekitt-container .feature-3 .content p {
    padding-right: 0;
  }
  .ekitt-container .ekitt-demo {
    transform: translateX(-7.5%);
    width: 117%;
  }
  .ekitt-container .ekitt-demo .demo-pic {
    margin-top: 0;
  }
}

.design.formosan-container section {
  width: 100%;
  height: auto;
}

.design.formosan-container section .photo img {
  width: 100%;
}

.design.formosan-container section .content {
  box-sizing: border-box;
}

.design.formosan-container section .content h3 {
  font-size: 30px;
  margin-bottom: 14px;
}

.design.formosan-container section .content p {
  flex: 1;
  font-size: 18px;
  line-height: 1.56;
  white-space: normal;
}

.design.formosan-container h2 {
  font-family: "Amiri", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
  font-size: 63px;
  line-height: 1.2;
  color: #4c4c4c;
  opacity: 0.2;
}

.design.formosan-container .cover {
  margin-bottom: 66px;
}

.design.formosan-container .section-1 {
  margin-bottom: 63px;
}

.design.formosan-container .section-1 .photo img {
  filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.32));
}

.design.formosan-container .section-1 .content {
  position: relative;
  margin: 4.93% 0 0 18.47%;
}

.design.formosan-container .section-1 .content .line-sec-1 {
  position: absolute;
  margin-top: calc(-19.58% + 20px);
  left: -12%;
  width: 10.67%;
}

.design.formosan-container .section-2 {
  position: relative;
  margin-bottom: 56px;
  padding-bottom: 70.55%;
}

.design.formosan-container .section-2 .photo {
  position: absolute;
  height: 100%;
}

.design.formosan-container .section-2 .photo img {
  width: auto;
  height: 100%;
  filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.32));
}

.design.formosan-container .section-2 .content {
  position: absolute;
  top: 13.12%;
  left: 43.11%;
  white-space: normal;
}

.design.formosan-container .section-2 .content .line-sec-2 {
  position: absolute;
  top: 17px;
  left: -21%;
  width: 18.7%;
}

.design.formosan-container .section-2 .icons {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 28.78%;
}

.design.formosan-container .section-2 .icons img {
  width: 100%;
}

.design.formosan-container .section-3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.design.formosan-container .section-3 .slick-item {
  width: 31.8%;
  max-width: 284px;
}

.design.formosan-container .section-3 .slick-item img {
  width: 100%;
}

.design.formosan-container .section-4 {
  margin-bottom: 15%;
}

@media all and (max-width: 800px) {
  .design.formosan-container article {
    padding: 0 36px;
  }
  .design.formosan-container h2 {
    font-size: 48px;
  }
  .design.formosan-container .cover {
    margin-bottom: 24px;
  }
  .design.formosan-container .cover .photo {
    height: 73.31vw;
  }
  .design.formosan-container .cover .photo img {
    height: 100%;
    object-fit: cover;
  }
  .design.formosan-container .section-1 {
    margin-bottom: 40px;
  }
  .design.formosan-container .section-1 .content {
    margin: 4.8% 0 0 14.24%;
  }
  .design.formosan-container .section-1 .content .line-sec-1 {
    left: -9%;
    margin-top: calc(-13.4% + 20px);
    width: 7.32%;
  }
  .design.formosan-container .section-2 {
    margin-bottom: 64px;
  }
  .design.formosan-container .section-2 .photo img {
    filter: drop-shadow(0 9px 19px rgba(0, 0, 0, 0.05));
  }
  .design.formosan-container .section-3 {
    margin-bottom: 74px;
  }
}

@media all and (max-width: 580px) {
  .design.formosan-container h2 {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 10px;
  }
  .design.formosan-container article {
    padding: 0 14px;
  }
  .design.formosan-container section .content h3 {
    font-size: 24px;
    line-height: 1.12;
  }
  .design.formosan-container section .content p {
    font-size: 16px;
    line-height: 1.69;
  }
  .design.formosan-container .cover .photo {
    height: 85.33vw;
  }
  .design.formosan-container .section-1 {
    margin-bottom: 54px;
  }
  .design.formosan-container .section-1 .content {
    margin: 20px 0 0;
  }
  .design.formosan-container .section-1 .content .line-sec-1 {
    display: none;
  }
  .design.formosan-container .section-2 {
    margin-bottom: 50px;
    padding-bottom: 100%;
  }
  .design.formosan-container .section-2 .photo img {
    filter: drop-shadow(0 4px 9px rgba(0, 0, 0, 0.05));
  }
  .design.formosan-container .section-2 .content {
    top: 0;
    left: 53.54%;
  }
  .design.formosan-container .section-2 .content .line-sec-2 {
    display: none;
  }
  .design.formosan-container .section-2 .icons {
    display: none;
  }
  .design.formosan-container .section-3 {
    margin-left: -14px;
    margin-bottom: 60px;
    width: calc(100% + 28px);
  }
  .design.formosan-container .section-3 .slick-item {
    padding: 0 10px;
    width: auto;
    height: 104vw;
    max-width: initial;
  }
  .design.formosan-container .section-3 .slick-item img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }
  .design.formosan-container .section-3 .slick-dots {
    bottom: -40px;
  }
  .design.formosan-container .section-3 .slick-dots li {
    margin: 0;
  }
  .design.formosan-container .section-4 .photo img {
    width: 100%;
  }
}

.apah-container .cover {
  margin-bottom: 93px;
}

.apah-container .cover .photo {
  width: 100%;
}

.apah-container .cover .photo img {
  width: 100%;
}

.apah-container .video {
  margin: 0 4.27% 93px;
  position: relative;
  width: 91.46%;
  height: 0;
  padding-bottom: 56.25%;
}

.apah-container .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.apah-container .devices .photo {
  width: 100%;
}

.apah-container .devices .photo img {
  width: 100%;
}

@media all and (max-width: 1026px) {
  .apah-container .cover .photo img {
    width: 100%;
  }
}

@media all and (max-width: 800px) {
  .apah-container .cover {
    margin: 0 30px 98px;
  }
  .apah-container .video {
    margin: 0 30px 113px;
  }
}

@media all and (max-width: 580px) {
  .apah-container .cover {
    margin: 0 13px 39px;
  }
  .apah-container .video {
    margin: 0 13px 54px;
  }
}

.laravel-conf-tw-2017-container * {
  position: relative;
  font-family: "NettoOT";
  color: #363531;
  white-space: normal;
  width: unset;
}

.laravel-conf-tw-2017-container section {
  width: 100%;
  height: auto;
  display: flex;
}

.laravel-conf-tw-2017-container.design {
  background-color: #f8f8f8;
}

.laravel-conf-tw-2017-container.design section {
  width: 100%;
}

.laravel-conf-tw-2017-container.design section img {
  width: 100%;
  margin-top: 50px;
}

.laravel-conf-tw-2017-container.design .section-1 {
  margin: 0 0 5% 0;
}

.laravel-conf-tw-2017-container.design .section-1 .photo {
  margin: 2.4% 6% 0 8.6%;
  box-sizing: border-box;
}

.laravel-conf-tw-2017-container.design .section-1 .photo img {
  margin-top: 20px;
}

.laravel-conf-tw-2017-container.design .section-2 {
  margin-bottom: 50px;
}

.laravel-conf-tw-2017-container.design .title-controller {
  width: 60vw;
  height: 27vw;
  position: absolute;
  top: 16%;
  opacity: 0;
  background-color: #000;
}

.laravel-conf-tw-2017-container.design .text-bubble {
  position: relative;
  margin: 5% 0;
  width: 100%;
  height: 10vw;
}

.laravel-conf-tw-2017-container.design .text-bubble img {
  width: 47%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.laravel-conf-tw-2017-container.design .header-container h1 {
  margin: 0 auto;
  font-family: 'Raleway', sans-serif;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: 4px;
  color: #ec6d65;
  max-width: 600px;
  padding: 80px 0 80px;
  text-shadow: 0px -0.06px 0 #c35953, 1.15px 0.86px 0 #c35953,2.31px 1.79px 0 #c35953,3.47px 2.73px 0 #c35953;
}

.laravel-conf-tw-2017-container.design .header-container iframe {
  display: none;
}

@media all and (max-width: 1026px) {
  .laravel-conf-tw-2017-container.design .header-container iframe {
    display: block;
    width: 100%;
    margin: 50px 0;
  }
  .laravel-conf-tw-2017-container.design .header-container h1 {
    display: none;
  }
  .laravel-conf-tw-2017-container.design .text-bubble {
    display: none;
  }
}

@media all and (max-width: 800px) {
  .laravel-conf-tw-2017-container .design .header-container h1 {
    margin-top: 30px;
  }
}

@media all and (max-width: 580px) {
  .laravel-conf-tw-2017-container .design .text-bubble {
    display: none;
  }
  .laravel-conf-tw-2017-container .design .header-container h1 {
    margin: 30px auto 0px;
    font-size: 30px;
    padding: 20px 0;
  }
}

.foolishfarm-container section {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  flex: 1;
  box-sizing: border-box;
}

.foolishfarm-container section h4 {
  opacity: 0.6;
  font-family: 'Playfair Display';
  font-size: 19px;
  color: #3d473f;
  letter-spacing: 1px;
}

.foolishfarm-container section h4 span {
  margin-left: 9px;
  font-size: 50px;
  letter-spacing: 1px;
  font-family: 'Playfair Display';
}

.foolishfarm-container section .sec-title {
  font-family: 'Playfair Display';
  font-size: 48px;
  color: #3d473f;
  letter-spacing: 1px;
  margin: 1.5% 0;
}

.foolishfarm-container section .photo {
  width: 100%;
  max-width: 677px;
}

.foolishfarm-container section .photo img {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(154, 154, 154, 0.5);
}

.foolishfarm-container section .content.left {
  margin-left: 58px;
}

.foolishfarm-container section .content.right {
  margin-right: 45px;
}

.foolishfarm-container .cover {
  margin-top: 5%;
  padding: 0 8%;
}

.foolishfarm-container .cover .photo img {
  width: 100%;
  box-shadow: unset;
}

.foolishfarm-container .product-view, .foolishfarm-container .checkout, .foolishfarm-container .delivery-information, .foolishfarm-container .payment {
  margin: 25px 0;
}

.foolishfarm-container .product-view .photo img, .foolishfarm-container .checkout .photo img, .foolishfarm-container .delivery-information .photo img, .foolishfarm-container .payment .photo img {
  width: 100%;
}

.foolishfarm-container .product-view {
  margin-top: 55px;
}

.foolishfarm-container .payment {
  margin-bottom: 30%;
}

.foolishfarm-container .bg-pic {
  position: absolute;
  z-index: 0;
}

.foolishfarm-container .bg-pic img {
  width: 100%;
}

.foolishfarm-container .bg-pic.family {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.foolishfarm-container .bg-pic.pumpkin {
  top: 50%;
}

.foolishfarm-container .bg-pic.mountain {
  right: 0;
  top: 11%;
}

.foolishfarm-container.design {
  background-color: #e0e5e0;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 1026px) {
  .foolishfarm-container section .content.left, .foolishfarm-container section .content.right {
    float: none;
    margin: 0 6%;
  }
}

@media all and (max-width: 800px) {
  .foolishfarm-container .pumpkin {
    top: 48%;
  }
  .foolishfarm-container .mountain {
    top: 8%;
  }
  .foolishfarm-container section .sec-title {
    font-size: 43px;
    font-weight: normal;
  }
}

@media all and (max-width: 580px) {
  .foolishfarm-container section .content .photo {
    width: 100%;
    min-width: 80vw;
  }
  .foolishfarm-container section .content .photo img {
    width: 100%;
  }
  .foolishfarm-container section .sec-title {
    font-size: 20px;
  }
  .foolishfarm-container section .name {
    font-size: 10px;
  }
  .foolishfarm-container section .name span {
    font-size: 15px;
  }
  .foolishfarm-container .bg-pic.pumpkin {
    width: 50%;
  }
  .foolishfarm-container .bg-pic.mountain {
    width: 50%;
    top: 8%;
  }
}
