.recruit-cover-info {
	margin: 5% auto;
	text-align: left; }
.recruit-type-container {
	display: flex;
	max-width: 850px;
	min-height: 100px;
	box-sizing: border-box;
	overflow: hidden;
	margin: 0px auto;
	font-family: $english_font, $chinese_font;
	letter-spacing: 1.3px;
	color: $main_gray;
	font-size: 20px;
	font-weight: bold;
	.recruit-title-container {
		align-self: center;
		flex: 2; }
	.reg-button {
		align-self: center; }
	p {
		margin: 3px 0px;
		font-family: $english_font, $chinese_font; } }
@media all and (max-width: $ipad-pro) {
	.recruit-type-container, .recruit-cover-info {
		width: 90vw; } }
@media all and (max-width: $mobile) {
	.recruit-type-container {
		background-color: #faf7f5;
		margin-top: 3%;
		.recruit-title-container {
			margin: 6%;
			min-width: 55%;
			p {
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 1px; } }
		button {
			&.reg-button {
				padding: 0;
				margin: 20px 20px 20px 0;
				width: 66px;
				height: 35px;
				font-size: 14px;
				border: solid 2px #363631;
				text-align: center;
				box-sizing: border-box; } } } }
