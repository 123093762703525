.banner-container {
	margin: 0 auto 0;
	position: relative;
	width: 100%;
	height: auto;
	.slick-slider {
		margin-bottom: 0; }
	.slick-wrap {
		width: 100%;
		.slick-list {
			width: 100%; }
		.slick-dots {
			bottom: 10px;
			z-index: 1; }
		.slick-item {
			position: relative;
			// max-width: 1420px
			width: 100%;
			.desktop-view {
				display: block; }
			.tablet-view, .mobile-view {
				display: none; }
			img {
				object-fit: cover;
				width: 100%;
				height: auto; }
			.content {
				position: absolute;
				display: block;
				width: 30%;
				z-index: 10;
				.banner-title {
					font-family: $english_font;
					font-size: 30px;
					font-weight: bold;
					text-align: left; }
				p {
					font-family: $english_font;
					font-size: 18px;
					line-height: 1.44;
					letter-spacing: 1px;
					text-align: left;
					margin: 4% 0; }
				a {
					display: inline-block;
					font-family: $english_font;
					font-size: 14px;
					font-weight: bold;
					text-align: center;
					border: solid 1px;
					padding: 8px 28px;
					margin-top: 2%;
					border-radius: 2px;
					cursor: pointer;
					transition: all .3s ease-in-out; }
				&.foolishfarm {
					top: 27%;
					right: 5%;
					width: 33%;
					.banner-title {
						color: #004405; }
					p {
						color: #363531; }
					a {
						border-color: #004500;
						color: #004500;
						&:hover {
							background-color: #004405;
							color: white; } } }
				&.ekitt {
					top: 28%;
					left: 31%;
					.banner-title {
						color: #58585b; }
					p {
						color: #58585b; }
					a {
						border-color: #58585b;
						color: #58585b;
						&:hover {
							background-color: #3c3c3c;
							color: white; } } }
				&.innovex {
					top: 27%;
					right: 18%;
					.banner-title {
						color: #fdcc1a; }
					p {
						color: #ffffff;
						text-shadow: 0 1px 2px rgba(25, 25, 25, 0.51); }
					a {
						border-color: #ffffff;
						color: #ffffff;
						&:hover {
							background-color: #ffdf24;
							color: #3c3c3c;
							border-color: #ffdf24; } } }

				&.laravelconf {
					top: 23%;
					right: 11%;
					.banner-title {
						color: #ee6c63; }
					p {
						color: #4a4a4a; }
					a {
						border-color: #ee6c63;
						color: #ee6c63;
						&:hover {
							background-color: #ee6c63;
							color: white; } } } } } } }

.center-p {
	max-width: 996px;
	width: 90vw;
	margin: 0 auto;
	font-size: 16px;
	line-height: 1.56;
	letter-spacing: 1px;
	text-align: center;
	font-family: $english_font;
	color: $main_gray; }

.home-service-container {
	width: 850px;
	min-height: 20px;
	margin: 60px auto 20px;
	display: flex;

	.home-service-item-container {
		flex: 1;
		width: 33.33%;
		text-align: center;
		p {
			font-family: $english_font;
			font-size: 20px;
			font-weight: bold;
			text-align: left;
			color: #363530;
			text-align: center;
			white-space: nowrap;
			margin-top: 5%; } } }

.short-service {
	width: 120px; }

.home-project-container {
	display: flex;
	flex-wrap: wrap;
	max-width: 1230px;
	margin: 0 auto;
	overflow: auto;
	font-family: $english-font, $chinese-font;
	.works {
		align-self: flex-start; } }

.footer-info-container {
	height: 200px;
	display: block;

	.logo-info {
		margin: -13px 30px 0px 100px;
		line-height: 46px;
		font-size: 18px;
		letter-spacing: 1.3px;
		color: $main_gray; }
	.datail-info {
		width: 600px;
		p {
			margin: 0px 10px 10px;
			line-height: 20px; }
		img {
			margin: 0px 10px;
			width: 20px;
			height: 20px; } } }

@media all and (max-width: $ipad-pro) {
	.banner-container {
		.slick-wrap {
			.slick-item {
				.content {
					.banner-title {
						font-size: 28px; }
					p {
						font-size: 16px;
						letter-spacing: 1px;
						margin: 3% 0; }
					a {
						display: none; }
					&.foolishfarm {
						top: 23%;
						right: 5%;
						width: 33%; }
					&.ekitt {
						top: 25%;
						left: 31%;
						width: 30%; }
					&.innovex {
						top: 22%;
						right: 12%; }
					&.laravelconf {
						top: 25%; } } } } }

	.home-project-container {
		width: calc(90vw + 30px); } }

@media all and (max-width: $tablet) {
	.banner-container {
		.slick-wrap {
			.slick-item {
				.tablet-view {
					display: block; }
				.desktop-view, .mobile-view {
					display: none; }
				.content {
					.banner-title {
						font-size: 26px; }
					p {
						font-size: 14px;
						letter-spacing: .8px;
						margin: 3% 0 0 0; }
					a {
						display: none; }
					&.foolishfarm {
						top: 33%;
						right: 6%;
						width: 30%; }
					&.ekitt {
						top: 21%;
						left: 40%;
						width: 39%; }
					&.innovex {
						top: 24%;
						right: 10%;
						width: 35%; }
					&.laravelconf {
						top: 25%;
						right: 13%; } } } } }

	.home-service-container {
		width: 90vw;

		.home-service-item-container {
			p {
				font-size: 18px;
				span {
					display: block;
					margin: 1% 0; } } } }
	.home-project-container {
		width: initial;
		margin: 0 17px; }

	.center-p {
		max-width: 627px; } }

@media all and (max-width: $mobile) {
	.banner-container {
		.slick-wrap {
			.slick-item {
				.mobile-view {
					display: block; }
				.tablet-view, .desktop-view {
					display: none; }
				.content {
					.banner-title {
						font-size: 21px;
						text-align: center; }
					p {
						display: none; }
					a {
						display: none; }
					&.foolishfarm, &.ekitt, &.innovex, &.laravelconf {
						top: initial;
						left: initial;
						right: initial;
						bottom: 10vw;
						width: 100%; } } } } }

	.home-service-container {
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		.home-service-item-container {
			width: 100%;
			margin: 5% 0;
			p {
				font-size: 16px;
				span {
					display: inline; } } } }

	.center-p {
		line-height: 1.57; }

	.home-project-container {
		margin: 0 14px; } }
