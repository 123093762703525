// Mixins
@mixin size($w, $h) {
	width: $w;
	height: $h; }

.foolishfarm-container {
	section {
		@include size(100%, auto);
		display: block;
		position: relative;
		flex: 1;
		box-sizing: border-box;
		// transform: translateZ(2px)

		h4 {
			opacity: 0.6;
			font-family: 'Playfair Display';
			font-size: 19px;
			color: #3d473f;
			letter-spacing: 1px;
			span {
				margin-left: 9px;
				font-size: 50px;
				letter-spacing: 1px;
				font-family: 'Playfair Display'; } }
		.sec-title {
			font-family: 'Playfair Display';
			font-size: 48px;
			color: #3d473f;
			letter-spacing: 1px;
			margin: 1.5% 0; }
		.photo {
			width: 100%;
			max-width: 677px;
			img {
				width: 100%;
				height: 100%;
				box-shadow: 0 2px 4px 0 rgba(154, 154, 154, 0.5); } }
		.content {
			&.left {
				margin-left: 58px; }
			&.right {
				margin-right: 45px; } } }
	.cover {
		margin-top: 5%;
		padding: 0 8%;
		.photo {
			img {
				width: 100%;
				box-shadow: unset; } } }

	.product-view, .checkout, .delivery-information, .payment {
		margin: 25px 0;
		.photo {
			img {
				width: 100%; } } }
	.product-view {
		margin-top: 55px; }
	.payment {
		margin-bottom: 30%; }

	.bg-pic {
		position: absolute;
		z-index: 0;
		img {
			width: 100%; }
		&.family {
			bottom: 0;
			left: 50%;
			transform: translateX(-50%); }
		&.pumpkin {
			top: 50%; }
		&.mountain {
			right: 0;
			top: 11%; } }
	&.design {
		background-color: #e0e5e0;
		position: relative;
		display: flex;
		flex-direction: column; } }

@media all and (max-width: $ipad-pro) {
	.foolishfarm-container {
		section {
			.content {
				&.left, &.right {
					float: none;
					margin: 0 6%; } } } } }

@media all and (max-width: $tablet) {
	.foolishfarm-container {
		.pumpkin {
			top: 48%; }
		.mountain {
			top: 8%; }
		section {
			.sec-title {
				font-size: 43px;
				font-weight: normal; } } } }

@media all and (max-width: $mobile) {
	.foolishfarm-container {
		section {
			.content {
				.photo {
					width: 100%;
					min-width: 80vw;
					img {
						width: 100%; } } }
			.sec-title {
				font-size: 20px; }
			.name {
				font-size: 10px;
				span {
					font-size: 15px; } } }
		.bg-pic {
			&.pumpkin {
				width: 50%; }
			&.mountain {
				width: 50%;
				top: 8%; } } } }

