//Project Theme
$ekitt-blue: #48c0cb;

// Mixins
@mixin size($w, $h) {
	width: $w;
	height: $h; }

.ekitt-container {
	h1, h2, h3, h4, h5, h6, p {}
	font-family: $english_font;
	color: $main_gray;
	white-space: normal;
	width: unset;
	section {
		@include size(100%, auto);
		display: flex;
		&.photo {
			flex: 1;
			padding: 0 2%;
			img {
				width: 100%; } }
		.content {
			flex: 1;
			box-sizing: border-box;
			h3 {
				font-size: 30px;
				text-align: left;
				margin-bottom: 15px;
				margin-top: 4%; }
			p {
				flex: 1;
				font-size: 18px;
				line-height: 1.56; } } }

	h2 {
		color: $ekitt-blue;
		text-align: center;
		margin-top: 50px;
		margin-bottom: 15px; }
	.line {
		background-color: $ekitt-blue;
		@include size(50px, 2px);
		margin-left: auto;
		margin-right: auto; }
	.cover {
		.photo {
			padding: 0 2%;
			img {
				@include size(100%, auto); } }
		.content {
			@include size(26.3%, auto);
			padding-top: 16%;
			margin-left: 26px;
			h1, {
				margin-top: 0;
				text-align: left;
				margin-top: 24px;
				font-size: 30px; }
			p {
				text-align: left;
				margin-top: 14px;
				font-size: 18px;
				line-height: 1.56; }
			.logo {
				@include size(60px, 60px);
				margin-top: 20px; }
			.apps {
				float: left;
				margin-top: 30px;
				img {
					@include size(49%, auto); } } } }
	.intro {
		margin: 10px auto 0 auto;
		flex-direction: column;
		flex-wrap: wrap;
		.photo {
			width: 100%;
			img {
				width: 100%; } }
		.content {
			display: flex;
			p {
				flex: 1;
				padding: 5px 45px; } } }
	.feature-1, .feature-2, .feature-3 {
			.photo, .content {
				flex: 1;
				width: 50%;
				img {
					width: 100%; } } }
	.feature-1 {
		margin: 2% 0;
		.content {
			margin: 15% 0 0 5%;
			p {
				flex: 1; } } }

	.feature-2 {
		margin: 10% 0;
		position: relative;
		.photo {
			img {
				margin-left: 18px; } }
		.content {
			position: absolute;
			width: 380px;
			left: 52%;
			top: 12%; } }

	.feature-3 {
		.photo {
			order: 2; }
		.content {
			padding: 125px 10% 0;
			br {
				display: none; } } }

	.ekitt-demo {
		.demo-pic {
			width: 100%;
			margin-top: 8%;
			img {
				width: 100%; } } } }

@media all and (max-width: $ipad-pro) { // 1026px
	.ekitt-container {
		.cover {
			.photo {
				img {
					width: 100%;
					height: auto; } } }
		h2 {
			margin-top: 100px; }
		.intro {
			max-width: 100%;
			.content p {
				padding: 5px 30px;
				line-height: 1.75; } }
		.feature-1, .feature-2, .feature-3 {
			.photo img {
				width: 100%;
				height: auto; }
			.content {
				p {
					line-height: 1.75; } } }
		.feature-2 {
			.photo {
				img {
					margin-left: 0; } } }
		.feature-3 {
			.content {
				margin: 80px 0 0 25px;
				padding: 0;
				p {
					padding-right: 50px; } } } } }
@media all and (max-width: $tablet) { //770px
	.ekitt-container {
		margin: 46px 32px 0;

		.cover {
			.photo {
				min-width: 250px;
				max-width: 350;
				flex: 1; }
			.content {
				min-width: 250px;
				flex: 1;
				padding-top: 7%;
				.apps {
					margin-top: 20%; } } }
		.feature-1, .feature-2, .feature-3 {
			.photo, .content {
				flex: 1; } }
		.feature-1 {
			.content {
				width: 50%;
				margin: 10% 0 0 5%;
				p {
					width: 100%; } } }

		.feature-2 {
			.photo {
				margin-top: 7%; }
			.content {
				top: 0;
				right: -5%;
				max-width: 45vw;
				p {
					width: 90%; } } }
		.feature-3 {
			.content {
				br {
					display: block; } } } } }


@media all and (max-width: $mobile) { //500px
	.ekitt-container {
		margin: 46px 15px 0;

		h2 {
			margin-top: 50px; }
		section {
			display: flex;
			flex-direction: column;
			width: 100%;
			.content {
				p {
					font-size: 16px; } } }
		.cover {
			.photo img {
				width: 100%;
				height: auto; }
			.content {
				margin: 0;
				width: 100%;
				text-align: center;
				.logo {
					margin-top: 0; }
				h3 {
					text-align: center; }
				p {
					text-align: left;
					font-size: 16px; }
				.apps {
					float: none;
					margin-top: 20px;
					img {
						width: 97.5px;
						height: 30px; } } } }
		.intro {
			max-width: 100%;
			.content {
				margin-top: 10px;
				flex-direction: column;
				p {
					padding: 0;
					margin-bottom: 15px; } } }
		.feature-1 {
			.photo {
				width: 100%;
				img {
					margin-bottom: 5%; } }
			.content {
				margin: 0;
				width: 100%;
				padding: 0 2%;
				p {
					width: auto; } } }
		.feature-2 {
			position: static;
			.photo {
				width: 100%; }
			.content {
				position: static;
				margin: 0;
				width: 100%;
				max-width: unset;
				padding: 0 2%;
				p {
					width: 100%; } } }
		.feature-3 {
			.photo {
				order: 1;
				width: 100%;
				img {
					max-width: 480px;
					margin-bottom: 10%; } }
			.content {
				margin: 0;
				order: 2;
				width: 100%;
				padding: 0 2%;
				p {
					padding-right: 0; } } }
		.ekitt-demo {
			transform: translateX(-7.5%);
			width: 117%;
			.demo-pic {
				margin-top: 0; } } } }
