.recruit-info-wrap {
	float: left;
	display: auto;
	width: 700px;
	margin: 100px 0px 0px 130px;
	min-height: 100px;
	overflow: hidden;

	.title-container {
		margin: 30px 5px -30px; }

	h2 {
		font-family: $chinese-font;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.19;
		letter-spacing: 1px;
		text-align: left;
		margin-bottom: 8px;
		color: #363531; }

	ul {
		list-style-type: disc;
		margin-left: 20px;
		font-size: 14px;
		line-height: 1.71;
		letter-spacing: 0.8px; } }

.recruit-form-wrap {
	min-height: 100px;
	overflow: hidden;

	.job-apply-title {
		margin: 85px 0px 25px 45px;
		width: 120px; }

	.recruit-form {
		float: right;
		margin-right: 120px;
		width: 420px;
		height: auto;
		overflow: auto;
		padding: 30px;
		border: solid 5px $bg_gray;
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 0.72);
		box-shadow: 0 2px 3px 0 rgba(64, 64, 64, 0.14);
		overflow-x: hidden;
		margin-bottom: 50px;


		h2 {
			font-family: $chinese-font;
			font-size: 18px;
			font-weight: 600;
			line-height: 1.19;
			letter-spacing: 1px;
			text-align: left;
			margin-bottom: 8px;
			color: $main_gray; }

		h3 {
			font-family: $chinese-font;
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0.2px;
			color: $main_gray;
			margin: 15px 0px 10px; }

		input[type=text], [type=tel], [type=email] {
			width: 410px;
			height: 40px;
			padding: 0px 5px;
			background-color: $bg_gray;
			font-size: 20px;
			border: none;
			text-align: right;

			&:hover {
				border: none; }
			&:active {
				border: none; }
			&:focus {
				outline: 0; } }

		.selection-wrap {
			width: 500px; }

		.single-selection {
			float: left;
			position: relative;

			&:after {
				top: 24px;
				right: 34px;
				content: "";
				position: absolute;
				border-style: solid;
				border-width: 0 0 12px 12px;
				border-color: transparent transparent $main_gray transparent; } }

		select {
			float: left;
			width: 120px;
			height: 40px;
			margin: 0px 30px 0px 0px;
			background-color: $bg_gray;
			padding: 10px;
			font-family: $english-font, $chinese-font;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			outline: 0;
			border: none; }

		.radio-outer-wrap {
			width: 460px;
			height: 40px;
			margin: 18px auto; }

		.small-radio-wrap {
			width: 90px;
			float: left;
			margin: 0px 12px; }

		input[type="radio"]+label {
			width: 90px;
			font-size: 14px;
			margin-left: 34px;
			font-weight: normal;
			font-family: $english-font, $chinese-font;

			&:before {} }

		textarea {
			display: block;
			font-family: $english-font;
			font-weight: bold;
			width: 400px;
			height: 291px;
			color: $light_gray;
			resize: none;
			border: none;
			padding: 10px;
			background-color: $bg_gray;
			margin: 10px auto;
			letter-spacing: 0.8px;

			&:focus {
				outline: none; } }
		.reg-button {
			width: 145px;
			height: 42px; } } }
