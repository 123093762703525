// Mixins
@mixin size($w, $h) {
	width: $w;
	height: $h; }

.laravel-conf-tw-2017-container {
	* {
		position: relative;
		h1, h2, h3, h4, h5, h6, p {}
		font-family: $english_font;
		color: $main_gray;
		white-space: normal;
		width: unset; }
	section {
		@include size(100%, auto);
		display: flex; } }

.laravel-conf-tw-2017-container {
	&.design {
		background-color: #f8f8f8;
		section {
			width: 100%;
			img {
				width: 100%;
				margin-top: 50px; } }
		.section-1 {
			margin: 0 0 5% 0;
			.photo {
				margin: 2.4% 6% 0 8.6%;
				box-sizing: border-box;
				img {
					margin-top: 20px; } } }

		.section-2 {
			margin-bottom: 50px; }
		.title-controller {
			width: 60vw;
			height: 27vw;
			position: absolute;
			top: 16%;
			opacity: 0;
			background-color: #000; }
		.text-bubble {
			position: relative;
			margin: 5% 0;
			width: 100%;
			height: 10vw;
			img {
				width: 47%;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				position: absolute; } }
		.header-container {
			h1 {
				margin: 0 auto;
				font-family: 'Raleway', sans-serif;
				font-size: 72px;
				font-weight: 900;
				letter-spacing: 4px;
				color: #ec6d65;
				max-width: 600px;
				padding: 80px 0 80px;
				text-shadow: 0px -0.06px 0 #c35953, 1.15px 0.86px 0 #c35953,2.31px 1.79px 0 #c35953,3.47px 2.73px 0 #c35953; }
			iframe {
				display: none; } } } }

@media all and (max-width: $ipad-pro) {
	.laravel-conf-tw-2017-container {
		&.design {
			.header-container {
				iframe {
					display: block;
					width: 100%;
					margin: 50px 0; }
				h1 {
					display: none; } }
			.text-bubble {
				display: none; } } } }
@media all and (max-width: $tablet) {
	.laravel-conf-tw-2017-container {
		.design {
			.header-container {
				h1 {
					margin-top: 30px; } } } } }
@media all and (max-width: $mobile) {
	.laravel-conf-tw-2017-container {
		.design {
			.text-bubble {
				display: none; }
			.header-container {
				h1 {
					margin: 30px auto 0px;
					font-size: 30px;
					padding: 20px 0; } } } } }
