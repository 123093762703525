@mixin keyframe ($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content; }
    @-moz-keyframes #{$animation-name} {
        @content; }
    @-o-keyframes #{$animation-name} {
        @content; }
    @keyframes #{$animation-name} {
        @content; } }

// Usage: @include animation(block-1-animate, $speed, ease-out, infinite)
@mixin animation ($animation, $duration, $transition, $iteration) {
    -webkit-animation-name: $animation;
    -webkit-animation-duration: $duration;
    -webkit-animation-timing-function: $transition;
    -webkit-animation-iteration-count: $iteration;
    -moz-animation-name: $animation;
    -moz-animation-duration: $duration;
    -moz-animation-timing-function: $transition;
    -moz-animation-iteration-count: $iteration;
    -o-animation-name: $animation;
    -o-animation-duration: $duration;
    -o-animation-timing-function: $transition;
    -o-animation-iteration-count: $iteration;
    animation-name: $animation;
    animation-duration: $duration;
    animation-timing-function: $transition;
    animation-iteration-count: $iteration; }

