.service-container {
	margin: 5% auto;
	min-height: 10px;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	.service-item-container {
		flex: 1;
		margin: 5%;
		display: flex;
		flex-direction: row;
		align-items: center;
		img {
			width: 130px;
			height: 130px;
			flex: 1; }
		.content {
			flex: 3;
			h3 {
				height: 24px;
				font-family: $english-font;
				font-size: 20px;
				font-weight: bold;
				letter-spacing: 1px; }
			p {
				font-family: $english-font;
				font-size: 16px;
				line-height: 1.56;
				letter-spacing: 1px;
				margin-top: 2%;
				padding-right: 6%; } } } }
@media all and (max-width: $mobile) {
	.service-container {
		.service-item-container {
			flex-direction: column;
			.content {
				text-align: center;
				h3 {
					margin: 10px 0; } } } } }
