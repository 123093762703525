$bg_gray: #faf7f5;
$main_yellow: #ffdf24;
$main_gray: #363531;
$light_gray: rgba(96, 94, 85, 0.72);

$title_yellow: #ffdf24;
$normal_font_color: #575757;
$normal_font_color_dark: #202020;
$title_font_color: #575757;

$ipad-pro: 1026px;
$tablet: 800px;
$mobile: 580px;

$font_family: "NettoOT", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
$chinese_font: "NettoOT", "Microsoft JhengHei", "PingFangTC", "Droid Sans", "WenQuanYi Zen Hei" /*"cwTexHei", "微軟正黑體", "Microsoft JhengHei", "PingFangTC"*/;
$english_font: "NettoOT";
$PingFang_font: "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
$OpenSans_font: "OpenSans", "PingFangTC", "微軟正黑體", "Microsoft JhengHei";
$Amiri_font: "Amiri", $PingFang_font;

/*@import url(//fonts.googleapis.com/earlyaccess/cwtexhei.css)* *///*思源黑體*/
/*@import url(//fonts.googleapis.com/earlyaccess/cwtexyen.css)* *///*思源圓體*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

@font-face {
	font-family: 'NettoOT';
	src: url('/asset/fonts/NettoOT.otf');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'OpenSans';
	src: url('/asset/fonts/OpenSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'Amiri';
	src: url('/asset/fonts/Amiri-Regular.ttf');
	font-weight: normal;
	font-style: normal; }

// @font-face
// 	font-family: 'Microsoft-JhengHei'
// 	src: url('/asset/fonts/msjh.ttf')
// 	font-weight: normal
// 	font-style: normal

@mixin preload-image($url) {
	&:after {
		content: "";
		display: none;
		background-image: url($url); } }
