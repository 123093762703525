@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,800');
.design {
    &.innovex {
        margin: 0; } }
.innovex-container {
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center 38.52%; // center
    background-repeat: no-repeat;
    .desktop-block {
        height: 43.67%; // 390/893
        margin: 0 auto;
        transform: translate(0);
        .video {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%; }
            img {
                width: 132%;
                position: absolute;
                left: -16%;
                top: -17%;
                object-fit: contain; } } }

    .info-block {
        padding: 0 9.63% 0 9.97%; // 0 89/893 0 86/893
        margin-bottom: 10.8%;
        margin-top: 5%;

        h3,p {
            font-family: $OpenSans_font;
            color: #4c4c4c; }
        h3 {
            margin-bottom: 2.46%; //22/893
            font-size: 30px;
            letter-spacing: 0px;
            font-style: italic;
            font-weight: 600; }
        p {
            font-size: 15px;
            line-height: 1.87;
            letter-spacing: 0.5px;
            white-space: normal; }
        img {
            &.match {
                    margin: 0 -2.2%;
                    width: 104.4%;
                    height: auto; } }
        .container {
            position: relative;
            width: 100%;
            height: 34.04vw; // 388/1440
            max-height: 388px;
            margin-top: 4.18%; // 30/718
            .attendees, .media, .exhibitor {
                position: absolute;
                width: 69.64%; // 500/718
                label {
                    display: block;
                    margin-bottom: 0.97%; // 7/718
                    line-height: 41px;
                    font-family: $OpenSans_font;
                    font-size: 20px;
                    font-weight: 800;
                    letter-spacing: 0px; }
                img {
                    width: 100%;
                    object-fit: contain;
                    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3); } }


            .attendees {
                label {
                    color: #251c99; } }
            .media {
                right: 0;
                label {
                    color: #fdcc1a; } }
            .exhibitor {

                label {
                    color: #e0007a; } } }

        .matching-img {
            width: calc(100% + 4.74%); // 17*2/718
            margin: 3.06% -2.37% 0; } }

    .device-block {
        height: 800px;
        h3 {
            font-family: $OpenSans_font;
            font-size: 30px;
            font-weight: 600;
            font-style: italic;
            letter-spacing: 0;
            color: #4c4c4c;
            margin-left: 7.8%; }

        .container__device-slick {
            position: relative;
            width: 100%;
            height: 630px;
            box-sizing: border-box;

            .tablet {
                position: absolute;
                width: 408px;
                height: 578px;
                bottom: 0;
                left: 22%;

                .tablet-img {
                    position: absolute;
                    width: 408px;
                    height: 578px;
                    object-fit: contain; }

                .tablet-carousel {
                    position: absolute;
                    width: 332px;
                    height: 440px;
                    top: 62px;
                    left: 41px;
                    max-width: 357px;

                    img {
                        width: 330px;
                        height: 440px;
                        object-fit: contain;
                        outline: none; }

                    .slick-dots {
                        bottom: -120px;
                        left: 50%;
                        transform: translateX(-25%);
                        li {
                            margin: 0;
                            button {
                                &:before {
                                    content: "";
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 9px;
                                    height: 9px;
                                    border-radius: 50%;
                                    background-color: #cdcdcd;
                                    opacity: 1;
                                    transition: all .2s; } }
                            button:hover {
                                &:before {
                                    width: 11px;
                                    height: 11px; } } }

                        li.slick-active {
                            button {
                                &:before {
                                    background-color: #242532;
                                    opacity: 1; } } } } } }

            .mobile {
                position: absolute;
                width: 189px;
                height: 383px;
                left: 57.2%;
                bottom: 0;
                .mobile-img {
                    position: absolute;
                    width: 189px;
                    height: 383px;
                    object-fit: contain; }

                .mobile-carousel {
                    position: absolute;
                    width: 156.5px;
                    height: 274px;
                    top: 49px;
                    left: 17px;

                    img {
                        width: 155px;
                        height: 274px;
                        object-fit: contain;
                        outline: none; } } } } } }

@media all and (max-width: 1260px) {
    .project-container {
        .design {
            &.innovex {}
            .innovex-container {
                .device-block {
                    .container__device-slick {
                        .tablet {
                            .tablet-carousel {
                                .slick-dots {
                                    bottom: -120px;
                                    left: 50%;
                                    transform: translateX(-32%); } } } } } } } } }

@media all and (max-width: $ipad-pro) {
    .project-container {
        .design {
            &.innovex {
                margin: 50px 0 0 0; }
            .innovex-container {
                .info-block {
                    .container {
                        height: 45vw; } }
                .device-block {
                    .container__device-slick {
                        .tablet {
                            .tablet-carousel {
                                .slick-dots {
                                    transform: translateX(-26%); } } } } } } } } }

@media all and (max-width: $tablet) {
    .project-container {
        .design {
            .innovex-container {
                .info-block {
                    padding: 0 52px;

                    h3 {
                        font-size: 30px;
                        font-weight: 600; }
                    p {
                        font-size: 18px;
                        line-height: 1.56; }
                    .container {
                        .attendees, .media, .exhibitor {
                            label {
                                font-size: 20px; } } } }
                .device-block {
                    .container__device-slick {
                        .tablet {
                            left: 18.1%;
                            .tablet-carousel {
                                .slick-dots {
                                    transform: translateX(-31%); } } }
                        .mobile {
                            left: 56.8%; } } } } } } }

@media all and (max-width: $mobile) {
    .project-container {
        .design {
            .innovex-container {
                .info-block {
                    margin-top: 55px;
                    padding: 0 13px;

                    h3 {
                        font-size: 24px;
                        line-height: 1.12;
                        letter-spacing: initial;
                        font-weight: 400;
                        font-style: normal; }
                    p {
                        font-size: 16px;
                        letter-spacing: initial;
                        line-height: 1.75; }
                    .container {
                        margin-top: 20px;
                        height: auto;

                        .attendees, .media, .exhibitor {
                            position: relative;
                            width: 100%;
                            label {
                                font-size: 14px;
                                line-height: normal;
                                margin-bottom: 5px; } } } }

                .device-block {
                    height: 500px;
                    h3 {
                        font-size: 24px;
                        line-height: 1.12;
                        letter-spacing: initial;
                        font-weight: 400;
                        font-style: normal; }
                    .container__device-slick {
                        height: 400px;
                        .tablet {
                            width: 258.4px;
                            height: 365.3px;
                            left: 10.4%;

                            .tablet-img {
                                width: 258.4px;
                                height: 365.3px; }
                            .tablet-carousel {
                                width: 211px;
                                height: 300px;
                                top: 28px;
                                left: 26px;
                                max-width: 357px;
                                img {
                                    width: 226.7px;
                                    height: 300px; }
                                .slick-dots {
                                    bottom: -80px;
                                    left: 50%;
                                    transform: translateX(-35%); } } }
                        .mobile {
                            width: 117.8px;
                            height: 242px;
                            left: 61.1%;

                            .mobile-img {
                                width: 117.8px;
                                height: 242px; }
                            .mobile-carousel {
                                width: 98px;
                                height: 182px;
                                top: 27px;
                                left: 10px;
                                img {
                                    width: 104px;
                                    height: 183.4px; } } } } } } } } }
