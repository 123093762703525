.contact_h2 {
	font-size: 20px; }

.contact-title-container {
	width: 130px;
	height: auto;
	margin: 82px auto 22px;
	.title {
		margin-bottom: 45px; } }


.contact-p {
	width: 795px;
	height: auto;
	min-height: 10px;
	overflow: hidden;
	font-family: $english-font;
	font-size: 16px;
	line-height: 1.93;
	letter-spacing: 1px;
	text-align: left;
	color: $main_gray; }

.contact-content {
	float: left; }

.contact-container {
	max-width: 850px;
	height: 120px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto 5% auto;

	.contact-content {
		flex: 1;
		font-family: $english-font, $chinese-font;
		color: $main_gray;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 1px;
		text-align: center;
		p {
			margin-top: 5%; } } }


.contact-option-container {
	font-family: $english-font;
	width: 795px;
	height: 50px;
	div {
		float: left;
		height: 25px;
		line-height: 25px;
		color: $main_gray;
		text-align: center;
		margin: 0px 36px;
		p {
			margin: 5px;
			float: left;
			font-weight: bold;
			letter-spacing: 1px; }

		.checked {
			display: inline-block;
			float: left;
			background-color: $main_yellow;
			text-align: left;
			width:  13px;
			height: 13px;
			border-top-right-radius: 2px;
			transform: rotate(90deg) translate(13px, -14px);
			margin-right: 22px;

			&:before {
				content: '';
				position: absolute;
				background-color: inherit;
				width:  13px;
				height: 13px;
				border-top-right-radius: 2px;
				transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%); }
			&:after {
				content: '';
				position: absolute;
				background-color: inherit;
				width:  13px;
				height: 13px;
				border-top-right-radius: 2px;
				transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%); } }


		.unchecked {
			margin: 5px;
			display: inline-block;
			width: 25px;
			height: 25px;
			border-radius: 2px;
			background-color: white;
			float: left; } } }

.info_input {
	width: 795px;
	height: auto;
	overflow: auto;
	h2 {
		font-size: 20px;
		font-weight: bold;
		text-align: left;
		color: #363530;
		margin-bottom: 15px;
		margin-top: 50px; }

	div {
		font-family: $english-font;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.19;
		letter-spacing: 1px;
		color: $main_gray; }

	h1 {
		text-align: left;
		width: 60%;
		margin-left: 0;
		margin-top: 30px;
		margin-bottom: 14px; }

	.input-block {
		float: left;
		width: calc(50% - 15px);
		height: 70px;
		position: relative;

		&:nth-of-type(odd) {
			margin: 10px 15px 10px 0; }

		&:nth-of-type(even) {
			margin: 10px 0 10px 15px; }

		div {
			margin-top: 2px; } }

	input[type="text"],[type="tel"],[type="email"] {
		z-index: 3;
		width: 100%;
		height: 45px;
		box-sizing: border-box;
		border: none;
		background-color: $bg_gray;
		font-family: $english-font;
		font-weight: bold;
		font-size: 20px;
		line-height: 0.95;
		letter-spacing: 1px;
		color: $main_gray;
		padding-right: 6px;
		padding-top: 5px;
		margin-top: 5px;
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		text-align: right;

		&:focus {
			outline: 0; } } }

.tell-us-more-block {
	&.center {
		margin-top: 50px; }
	width: 795px;
	h2 {
		font-size: 20px; }
	div {
		font-family: $english-font;
		font-weight: bold;
		font-size: 16px;
		line-height: 1.95;
		letter-spacing: 1px;
		color: $main_gray;
		padding-left: 4px; }

	.contact_textarea {
		display: block;
		font-family: $english-font;
		font-weight: bold;
		width: 100%;
		height: 283px;
		box-sizing: border-box;
		color: #363531;
		resize: none;
		border: none;
		padding: 20px;
		font-size: 20px;
		background-color: $bg_gray;
		margin: 10px auto 50px;
		letter-spacing: 1px;

		&:focus {
			outline: none; } } }

.done-button {
	width: 185px;
	height: 56px;
	border-radius: 2px;
	border: solid 2px #c9c9c9;
	background-color: transparent;
	font-family: $english-font;
	font-size: 16px;
	font-weight: bold;
	color: #424242;
	display: block;
	margin: 50px auto 100px; }
.type-container {
	width: 810px;
	min-height: 10px;
	overflow: hidden; }

.radio-wrap, .checkbox-wrap {
	float: left;
	height: 30px;
	width: 240px;
	margin: 10px 20px; }

.contact_us_table {
	.type-container {
		display: flex;
		flex-direction: column;
		.radio-wrap, .checkbox-wrap {
			padding-left: 16px; } } }
// .radio-wrap-small
// 	width: 120px

.radio-wrap-large {
	position: relative;
	float: left;
	height: 30px;
	width: 400px;
	margin: 20px; }

input[type="radio"], input[type="checkbox"] {
	display: none; }

input[type="radio"]+label, input[type="checkbox"]+label {
	position: relative;
	display: block;
	height: auto;
	background-color: white;
	float: left;
	font-family: $english-font;
	font-size: 16px;
	font-weight: bold;
	line-height: 25px;
	letter-spacing: 1px;
	color: $main_gray;
	margin-left: 10px;

	.small-label {
		width: 80px; }

	&:before {
		content: "";
		position: absolute;
		border: solid 2px #363531;
		width: 28px;
		height: 15px;
		left: -45px;
		top: 3px;
		background-color: white; } }

input[type="radio"]:checked+label, input[type="checkbox"]:checked+label {
	display: block;
	height: auto;
	float: left;

	&:before {
		background-color: $title_yellow; } }

.project-table {
	width: 795px;
	margin: 0px auto;
	h2 {
		text-align: left;
		width: 100%;
		font-size: 20px;
		font-weight: bold;
		text-align: left;
		color: #363530;
		&.project-table__title-type {
			margin-bottom: 5px;
			margin-top: 50px; }
		&.project-table__title-budget {
			margin-top: 35px; } } }

.slider-bar-container {
	width: 100%;
	position: relative;
	height: 20px;

	&.center {
		margin-top: 54px; }

	div {
		position: absolute;
		font-family: $english-font;
		font-weight: bold;
		line-height: 1.36;
		letter-spacing: 0.9px;
		color: $main_gray; }

	.to-budget {
		top: -40px;
		right: 0; }

	.from-budget {
		top: -40px;
		left: 0; } }

#budget-range {
    width: calc(100% - 50px);
    background-color: $light_gray;
    border: none;
    height: 2px;
    left: 25px;

    div {
        background-color: $main_yellow; }

    span {
        background-color: transparent; }

    span:active {
        outline: none;
        background-color: transparent; }

    span:focus {
        outline: none;
        background-color: transparent; }

    span:hover {
        outline: none;
        background-color: transparent; }

    span:nth-child(2) {
        top: -11px;
        margin-left: -25px;
        height: 0;
        width: 0;
        border-width: 12px 0 12px 25px;
        border-color: transparent transparent transparent black; }


    span:nth-child(3) {
        top: -11px;
        margin-left: 0;
        height: 0;
        width: 0;
        border-width: 12px 25px 12px 0px;
        border-color: transparent black transparent transparent; } }


@media all and (max-width: $tablet) {
	.contact-container {
		width: 90vw;

		.contact-content {
			margin: 30px 0; } }


	.contact-p {
		width: 90vw; }
	.contact-option-container {
		width: 90vw; }
	.contact_us_table {
		width: 90vw;
		margin: 0px auto; }
	.info_input {
		width: 100%;
		overflow: hidden;
		min-height: 10px;
		div {
			padding-left: 0px; }
		h1 {
			margin: 30px 0 15px; }
		h2 {
			margin-bottom: 15px; }

		.input-block {
			width: 46%;
			float: left;
			&:nth-child(2n) {
				margin: 10px 6% 10px 0px; }
			&:nth-child(2n-1) {
				margin: 10px 0; }

			div {
				margin-top: 2px; } }

		input[type="text"],[type="tel"],[type="email"] {
			z-index: 3;
			width: 100%; } }

	.tell-us-more-block {
		width: 100%; }

	.radio-wrap, .checkbox-wrap {
		margin: 20px 0px 20px 10px; }

	.project-table {
		width: 90vw;
		h1 {
			width: 100%; } }
	.type-container {
		width: 100%;
		padding-left: 30px; } }



@media all and (max-width: $mobile) {
	.contact-container {
		flex-direction: column;
		height: auto;
		.contact-content {
			font-size: 16px;
			letter-spacing: 0.9px; } }

	.contact-p {
		margin-bottom: 40px !important; }
	.radio-wrap, .checkbox-wrap {
		margin: 0 36px 15px !important; }

	.type-container {
		padding-left: 0; }

	.info_input {
		.input-block {
			width: 100%; } }
	.slider-bar-container {
		.to-budget {
			top: 25px; } } }
