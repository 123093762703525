@import "animation";
@include keyframe(highlight) {
	0% {
		width: 0px; }

	100% {
		width: 150px; } }

body {
	overflow-x: hidden;
	padding: 10px;
	background-color: $main_yellow;

	.mobile-show {
		display: none; }
	.phone-show {
		display: none; }

	.body-container {
		background-color: white; }

	.content-container {
		position: relative;
		padding-top: 110px;
		height: fit-content;
		min-height: calc(100vh - 211px); }

	.loading {
		z-index: 1000000;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0px;
		background-color: white;
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out; }

	.loadingout {
		opacity: 0;
		height: 0%;
		top: 100vh; }

	.lazy-hidden {
		opacity: 0; }

	.left {
		float: left; }

	.right {
		float: right; }

	img {
		-webkit-user-drag: none; }

	.center {
		margin: 0px auto; }

	.title-container-second-line {
		width: 280px;
		height: 22px;
		margin: 0px auto 60px; }

	.footer-tri {
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-left: 16px solid $main_yellow;
		border-bottom: 10px solid transparent; }

	.copy-right-ch {
		text-align: center;
		font-family: $chinese-font;
		font-size: 16px;
		letter-spacing: 1px; }

	.copy-right-en {
		text-align: center;
		font-family: $english-font;
		margin: 50px auto;
		letter-spacing: 1px;
		font-size: 16px;

		br {
			display: none; } }

	.hidden {
		display: none; } }

footer {
	height: 80px;
	bottom: 0px;
	width: 95vw;
	margin: 75px auto 0; }

#footer-with-border {
	height: auto;
	overflow: auto; }

.split-line {
	width: 75px;
	height: 1px;
	background-color: $main_gray;
	margin: 44px auto; }

.reg-button {
	display: block;
	position: relative;
	margin: 20px auto;
	width: 160px;
	height: 42px;
	background-color: white;
	border: solid 3px $main_gray;
	text-align: center;
	font-family: $english_font, $chinese_font;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1px;
	cursor: pointer;
	transition: all 0.1s linear;

	&:hover {
		background-color: $main_yellow;

		.layer {
			top: 0; } }

	.layer {
		position: absolute;
		background-color: $main_yellow;
		width: inherit;
		height: inherit;
		top: -50px;
		right: -2px;
		font-family: $english_font, $chinese_font;
		line-height: 38px;
		text-align: center;
		-webkit-transition: .2s ease-in-out;
		-moz-transition: .2s ease-in-out;
		-o-transition: .2s ease-in-out;
		transition: .2s ease-in-out; } }

.page-header {
	margin: 50px auto;
	width: fit-content;
	font-family: $font_family;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.33;
	text-align: center;
	color: #363631;

	&.home-title {
		margin: 47px auto 0; }

	br {
		display: none; } }

.yellow-bg {
	position: relative;
	z-index: 0;

	&:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 55%;
		margin: 0 3px;
		width: 0;
		height: 35%;
		background-color: #ffdf24;
		z-index: -1;
		transform: skew(-30deg);
		transition: 1s ease-in-out; }

	&.fill-bg {
		&:before {
			width: calc(100% - 6px); } } }

.margin-box {
	width: 100%;
	height: 30px; }

@media all and (max-width: $tablet) {
	body {
		padding: 8px;
		.mobile-show {
			display: block; }
		.mobile-not-show {
			display: none; }

		.content-container {
			padding-top: 72px;
			min-height: calc(100vh - 225px); }

		.copy-right-ch {
			font-size: 14px;
			line-height: 1.57;
			letter-spacing: 1px; }

		.copy-right-en {
			font-size: 14px;
			font-weight: bold;
			line-height: 1.36;
			letter-spacing: 1.5px; } }

	footer {
		margin-top: 90px; }

	#footer-with-border {
		line-height: 1.8; }

	.split-line {
		margin: 35px auto; }

	.page-header {
		margin: 30px auto;

		&.home-title {
			margin: 36px auto 0; }

		br {
			display: block; } } }

@media all and (max-width: $mobile) {
	body {
		padding: 5px;

		.phone-not-show {
			display: none; }
		.phone-show {
			display: block; }

		.copy-right-ch {
			font-size: 12px; }

		.copy-right-en {
			font-size: 12px;
			line-height: 1.33;
			letter-spacing: 1px;
			margin: 30px auto;

			br {
				display: block; } }

		.content-container {
			padding-top: 60px;
			min-height: calc(100vh - 160px); } }

	footer {
		margin-top: 60px; }

	.split-line {
		margin: 20px auto; }

	.reg-button {
		width: 121px;
		height: 38px;
		font-size: 14px;
		line-height: 1.71; }

	.page-header {
		margin: 20px auto;
		font-size: 18px;
		line-height: 1.44;

		&.home-title {
			margin: 40px auto 0; } }

	.margin-box {
		height: 20px; } }
