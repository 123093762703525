.mobile-nav-top {
	display: none; }

.desktop-nav-top {
	position: fixed;
	top: 0;
	width: calc(100% - 20px);
	height: 120px;
	border-top: solid 10px $main_yellow;
	background-color: rgba(255, 255, 255, .95);
	z-index: 99999;

	a {
		font-size: 0; }

	.nav-container {
		margin: 0 auto;
		padding: 0 30px;
		height: 100%;
		max-width: 1260px;
		display: flex;
		justify-content: space-between;
		align-items: center; }

	.nav-logo {
		position: relative;
		height: 46px;
		width: 46px;
		top: 25px;
		left: 15%; }

	.nav-logo-with-word {
		position: inherit;
		width: 102px;
		height: 35.3px; }

	ul {
		margin-top: 15px;
		width: 520px;
		height: 18px;
		float: right;
		display: flex;
		justify-content: space-between;

		li {
			float: right;
			margin: 0px 16.5px;
			text-align: center;
			position: relative;

			.nav-dec {
				position: absolute;
				top: 10px;
				left: -15px;
				width: 0px;
				height: 4px;
				background-color: $main_yellow;
				-webkit-transition: .2s ease-in-out;
				-moz-transition: .2s ease-in-out;
				-o-transition: .2s ease-in-out;
				transition: .2s ease-in-out; }

			.fb-icon {
				display: block;
				background-image: url(../../../asset/img/nav/nav-fb.svg);
				background-position: center;
				background-size: 8px 20px;
				background-repeat: no-repeat;
				width: 8px;
				height: 20px;
				transition: all .2s;
				@include preload-image('../../../asset/img/nav/nav-fb-h.svg'); }

			a {
				font-family: $chinese_font;
				font-size: 16px;
				line-height: normal;
				letter-spacing: 1px;
				color: $main_gray;
				transition: .2s ease-in-out;

				&:hover + .nav-dec, &.active + .nav-dec, &:active + .nav-dec {
					width: 10px;
					height: 3px;
					background-color: $main_yellow; }

				&.active {
					font-weight: bold; } } }

		li:hover {
			.fb-icon {
				background-image: url(../../../asset/img/nav/nav-fb-h.svg); } }

		li.facebook {
			width: 8px;
			margin-right: 0; } } }

.mobile-nav-list {
	display: none; }

@media all and (max-width: $tablet) {
	.desktop-nav-top {
		display: none; }

	.mobile-nav-top {
		display: block; }

	.mobile-nav-top {
		// width: calc( 100% - 16px)
		width: calc( 100% - 16px);
		height: 80px;
		// margin: 0px auto
		margin: 0 auto;
		background-color: rgba(255, 255, 255, 0.95);
		position: fixed;
		border-top: solid 8px $main_yellow;
		top: 0;
		z-index: 999;
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		&.open {
			background-color: transparent; }
		img {
			position: fixed;
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out; }
		.word {
			top: 30px;
			left: 40px;
			width: 109px;
			height: 35px;

			&.open {
				left: -130px; } }

		.icon {
			top: 30px;
			left: 114px;
			width: 35px;
			height: 34px;
			transform: rotate(115deg);
			opacity: 0;
			visibility: hidden;

			&.open {
				left: 48px;
				width: 40px;
				height: 40px;
				transform: rotate(0deg);
				opacity: 1;
				visibility: visible; } } }

	.burger {
		width: 24px;
		height: 18px;
		position: fixed;
		right: 42px;
		top: 42px;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		cursor: pointer;
		z-index: 11;
		span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: $main_gray;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
			&:nth-child(1) {
				top: 0px; }

			&:nth-child(2) {
				top: 8px;
				width: 80%; }

			&:nth-child(3) {
				top: 16px; } }
		&.open {
			span:nth-child(1) {
				top: 8px;
				-webkit-transform: rotate(135deg);
				-moz-transform: rotate(135deg);
				-o-transform: rotate(135deg);
				transform: rotate(135deg); }
			span:nth-child(2) {
				opacity: 0;
				left: -60px; }
			span:nth-child(3) {
				top: 8px;
				-webkit-transform: rotate(-135deg);
				-moz-transform: rotate(-135deg);
				-o-transform: rotate(-135deg);
				transform: rotate(-135deg); } } }

	.mobile-nav-list {
		display: block;
		width: 100%;
		height: 100%;
		position: fixed;
		top: -100vh;
		left: 0;
		background-color: rgba(250, 247, 245, 0.97);
		border: solid 8px $main_yellow;
		z-index: 10;
		overflow-y: scroll;
		-webkit-transition: .5s ease-in-out;
		-moz-transition: .5s ease-in-out;
		-o-transition: .5s ease-in-out;
		transition: .5s ease-in-out;
		visibility: hidden;

		&.open {
			top: 0;
			visibility: visible; }
		ul {
			text-align: center;
			font-size: 18px;
			letter-spacing: 1.5px;
			margin: 120px auto;
			li {
				margin-bottom: 35px;
				color: $main_gray;

				img {
					width: 8px;
					height: 20px; } }

			li.facebook {
				margin-top: 20px; } } } }

@media all and (max-width: $mobile) {
	.mobile-nav-top {
		height: 65px;
		width: calc(100% - 10px);
		border-top: solid 5px $main_yellow;
		&.open {
			background-color: transparent; }
		.word {
			top: 18px;
			left: 18px;
			width: 87px;
			height: 30px; }

		.icon {
			top: 18px;
			left: 79px;
			transform: rotate(115deg);
			width: 29px;
			height: 28px;

			&.open {
				top: 20px;
				left: 25px;
				width: 33px;
				height: 33px; } } }
	.burger {
		right: 18px;
		top: 25px; }

	.mobile-nav-list {
		border: solid 5px $main_yellow;

		ul {
			font-size: 16px;
			letter-spacing: 1.5px;
			margin: 80px auto;

			li {
				margin-bottom: 25px; }

			li.facebook {
				margin-top: 30px; } } } }
