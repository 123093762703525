.story-content {
	width: 788px;
	height: auto;
	font-family: $english-font;
	font-size: 16px;
	line-height: 1.56;
	letter-spacing: 1px;
	color: $main_gray;
	overflow: auto;
	text-align: center;
	margin: 0 auto;

	p {
		margin-bottom: 10px;
		text-align: left;
		width: 100%; } }
body {
	.long-title-container {
		&.about-title-slogan {
			width: 280px; } } }
.brand-container {

	float: left;
	text-align: center;
	font-size: 14px;
	font-family: $english-font;
	letter-spacing: 1.5px;
	opacity: .75;
	-webkit-transition: .2s ease-in-out {
    -moz-transition: .2s ease-in-out;
    -o-transition: .2s ease-in-out;
    transition: .2s ease-in-out; }
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);

	&:hover {
		opacity: 1;
		-webkit-filter: grayscale(0%);
		filter: grayscale(0%); } }

.big-margin {
	margin: 0px 50px; }

.mid-margin {
	margin: 5px 30px; }

.min-margin {
	margin: 10px 1px; }

.partners-container {
	padding-top: 10px;
	width: 878px;
	height: 100px;
	img {
		width: 100%; }

	.brand-container {
		width: calc(100% / 3 - 90px);
		margin: 5px 45px; } }

.award-container {
	padding-top: 10px;
	width: 416px;
	height: 100px;
	img {
		width: 100%; }

	.brand-container {
		width: calc(50% - 50px);
		margin: 0 25px; } }

.client-container {
	padding-bottom: 100px;
	width: 90vw;
	display: flex;
	flex-wrap: wrap;
	max-width: 1200px;
	min-height: 300px;
	overflow: hidden;
	img {
		width: 100%; }

	.brand-container {
		width: calc(100% / 7 - 2px); } }

@media all and (max-width: $ipad-pro) {
	.client-container, .partners-container, .story-content {
		width: 90vw; } }

@media all and (max-width: $tablet) {
	.story-content {
		width: 90%; }

	.client-container {
		padding-bottom: 50px;

		.brand-container {
			width: calc(100% / 5 - 5px);
			margin: 10px 2.5px; } }

	.partners-container {
		width: 90vw;

		.brand-container {
			width: calc(100% / 3 - 50px);
			margin: 5px 25px; } } }


@media all and (max-width: $mobile) {
	.client-container {
		padding-bottom: 0;

		.brand-container {
			width: calc(100% / 3 - 5px); } }

	.award-container {
		width: 90vw;

		.brand-container {
			width: calc(50% - 4px);
			margin: 5px 2px; } }

	.partners-container {
		display: flex;
		justify-content: center;
		height: auto;

		.brand-container {
			width: calc(100% / 3 - 4px);
			margin: 5px 2px; } } }
