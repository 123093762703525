//Project Theme
$title-grey: #4c4c4c;
$content-grey: #4a4a4a;

// Mixins
@mixin size($w, $h) {
	width: $w;
	height: $h; }

.design.formosan-container {

	section {
		@include size(100%, auto);
		.photo {
			img {
				width: 100%; } }
		.content {
			box-sizing: border-box;

			h3 {
				font-size: 30px;
				margin-bottom: 14px; }

			p {
				flex: 1;
				font-size: 18px;
				line-height: 1.56;
				white-space: normal; } } }
	h2 {
		font-family: $Amiri_font;
		font-size: 63px;
		line-height: 1.2;
		color: $title-grey;
		opacity: 0.2; }


	.cover {
		margin-bottom: 66px; }

	.section-1 {
		margin-bottom: 63px;

		.photo {
			img {
				filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.32)); } }

		.content {
			position: relative;
			margin: 4.93% 0 0 18.47%;

			.line-sec-1 {
				position: absolute;
				margin-top: calc(-19.58% + 20px);
				left: -12%;
				width: 10.67%; } } }

	.section-2 {
		position: relative;
		margin-bottom: 56px;
		padding-bottom: 70.55%;

		.photo {
			position: absolute;
			height: 100%;

			img {
				width: auto;
				height: 100%;
				filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.32)); } }

		.content {
			position: absolute;
			top: 13.12%;
			left: 43.11%;
			white-space: normal;

			.line-sec-2 {
				position: absolute;
				top: 17px;
				left: -21%;
				width: 18.7%; } }

		.icons {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 28.78%;

			img {
				width: 100%; } } }

	.section-3 {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;

		.slick-item {
			width: 31.8%;
			max-width: 284px;

			img {
				width: 100%; } } }

	.section-4 {
		margin-bottom: 15%; } }

@media all and (max-width: $tablet) {
	.design.formosan-container {
		article {
			padding: 0 36px; }

		h2 {
			font-size: 48px; }

		.cover {
			margin-bottom: 24px;

			.photo {
				height: 73.31vw;

				img {
					height: 100%;
					object-fit: cover; } } }

		.section-1 {
			margin-bottom: 40px;

			.content {
				margin: 4.8% 0 0 14.24%;

				.line-sec-1 {
					left: -9%;
					margin-top: calc(-13.4% + 20px);
					width: 7.32%; } } }

		.section-2 {
			margin-bottom: 64px;

			.photo {
				img {
					filter: drop-shadow(0 9px 19px rgba(0, 0, 0, 0.05)); } } }

		.section-3 {
			margin-bottom: 74px; } } }

@media all and (max-width: $mobile) {
	.design.formosan-container {
		h2 {
			font-size: 30px;
			line-height: 1;
			margin-bottom: 10px; }

		article {
			padding: 0 14px; }

		section {
			.content {
				h3 {
					font-size: 24px;
					line-height: 1.12; }
				p {
					font-size: 16px;
					line-height: 1.69; } } }
		.cover {
			.photo {
				height: 85.33vw; } }

		.section-1 {
			margin-bottom: 54px;

			.content {
				margin: 20px 0 0;

				.line-sec-1 {
					display: none; } } }

		.section-2 {
			margin-bottom: 50px;
			padding-bottom: 100%;

			.photo {
				img {
					filter: drop-shadow(0 4px 9px rgba(0, 0, 0, 0.05)); } }

			.content {
				top: 0;
				left: 53.54%;

				.line-sec-2 {
					display: none; } }

			.icons {
				display: none; } }

		.section-3 {
			margin-left: -14px;
			margin-bottom: 60px;
			width: calc(100% + 28px);

			.slick-item {
				padding: 0 10px;
				width: auto;
				height: 104vw;
				max-width: initial;

				img {
					width: auto;
					height: 100%;
					object-fit: contain; } }

			.slick-dots {
				bottom: -40px;
				li {
					margin: 0; } } }
		.section-4 {
			.photo {
				img {
					width: 100%; } } } } }
