.works-nav-container {
		max-width: 1240px;
		margin: 50px auto 35px;
		height: 22px;
		box-sizing: border-box; }

.works-nav {
	position: relative;
	float: left;
	font-family: $english-font;
	font-size: 18px;
	letter-spacing: 1.8px;
	color: $main_gray;
	margin: 0 40px 0;
	cursor: pointer;

	&:hover > .nav-dec, &.active-works-nav > .nav-dec {
		width: 10px;
		height: 4px;
		background-color: $main_yellow; } }

.nav-dec {
	width: 0px;
	height: 4px;
	position: absolute;
	top: 8px;
	left: -15px;
	background-color: $main_yellow;
	-webkit-transition: .2s ease-in-out;
	-moz-transition: .2s ease-in-out;
	-o-transition: .2s ease-in-out;
	transition: .2s ease-in-out; }


.active-works-nav {
	font-weight: bold; }

.works-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	max-width: 1230px;
	margin: 0 auto;
	overflow: auto;
	font-family: $english-font, $chinese-font;
	.works {} }


.works {
	width: calc(25% - 30px);
	height: calc(25% + 43px);
	margin: 15px;

	div {
		float: left; }
	.description {
		display: flex;
		flex-direction: row;
		width: 100%;
		img {
			width: 50px;
			align-self: flex-start; }

		.content {
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow-wrap: break-word;
			.work-subtitle {
				width: 100%; } } }

	.img-container {
		width: 100%;
		overflow: hidden;
		position: relative;
		&:before {
			content: "";
			display: block;
			padding-top: 100%; } }

	.work-photo {
		position: absolute;
		width: 100%;
		left: 0px;
		top: 0px;
		-webkit-transition: .4s ease-in-out;
		-moz-transition: .4s ease-in-out;
		-o-transition: .4s ease-in-out;
		transition: .4s ease-in-out;

		&:hover {
			width: 104%;
			left: -2%;
			top: -2%; } }

	.work-icon {
		float: left;
		margin: 10px 10px 0 0; }

	.work-title {
		width: 100%;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: .8px;
		color: $main_gray;
		margin: 15px 0 5px 0; }

	.work-subtitle {
		font-size: 16px;
		line-height: 1.36;
		letter-spacing: 0.9px;
		color: $main_gray;
		overflow: hidden; } }

.project-container {
	display: flex;
	max-width: 1260px;
	margin: 40px auto 0;
	padding: 0 30px;
	white-space: nowrap;
	width: 100%;
	box-sizing: border-box;

	.describe {
		width: 25.5%;
		margin: 0 2% 0 0;
		box-sizing: border-box;

		h3 {
			margin-bottom: 30px;
			font-family: $font_family;
			font-size: 30px;
			font-weight: bold;
			line-height: 1.33;
			letter-spacing: 1.3px;
			white-space: pre-wrap;
			color: $title_font_color; }

		.block {
			&:nth-child(2), &:nth-child(3) {
				margin-bottom: 17px; }

			&:nth-child(4) {
				margin-bottom: 32px; }

			h4 {
				font-family: $font_family;
				font-size: 20px;
				font-weight: bold;
				letter-spacing: 1px;
				margin-bottom: 3px;
				color: $title_font_color; }

			p {
				font-family: $chinese_font;
				font-size: 16px;
				line-height: 1.56;
				letter-spacing: 1px;
				color: $title_font_color;
				white-space: pre-wrap; } }

		.content {
			p {
				font-family: $font_family;
				font-size: 16px;
				line-height: 1.56;
				letter-spacing: 1px;
				color: $title_font_color;
				white-space: normal; } } }

	.design {
		width: 74.5%;
		box-sizing: border-box; } }

@media all and (max-width: 1210px) {
	.work-title {
		width: 74%; }

	.work-subtitle {
		width: 74%; } }

@media all and (max-width: $ipad-pro) {
	.works-nav-container {
		width: calc(100vw - 50px); }
	.works-wrap {
		width: calc(90vw + 30px); }

	.works {
		.work-photo {
			&:hover {
				width: 100%; } } } }


@media all and (max-width: $tablet) {
	.works-nav-container {
		width: initial;
		margin: 35px 10px 10px; }

	.works-wrap {
		width: initial;
		margin: 0 17px; }

	.works {
		width: calc(33.33% - 20px);
		height: calc(33.33% - 43px);
		margin: 20px 10px;

		.work-photo {
			height: auto;

			&:hover {
				left: 0;
				top: 0; } } }

	.project-container {
		flex-direction: column;
		margin-top: 20px;
		padding: 0;

		.describe {
			margin: 0 36px;
			width: initial;
			padding: 0;

			h3 {
				margin-bottom: 18px;
				font-size: 24px;
				line-height: normal; }

			.block {
				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 6px; }

				&:nth-child(4) {
					margin-bottom: 13px; }

				h4, p {
					display: inline-block;
					font-size: 18px;
					line-height: 1.56; }

				h4 {
					width: 95px; } }

			.content {
				p {
					font-size: 18px;
					line-height: 1.56; } } }

		.design {
			margin-top: 46px;
			width: initial; } } }

@media all and (max-width: $mobile) {
	.works-nav-container {
		margin: 30px 20px 10px;
		.works-nav {
			font-size: 14px;
			margin: 0 40px 0 0; } }
	.works-wrap {
		margin: 0 14px; }

	.works {
		width: calc(50% - 12px);
		height: calc(50% - 43px);
		margin: 20px 6px; }

	.project-container {
		.describe {
			margin: 0 15px;

			.block {
				white-space: normal;

				&:nth-child(2), &:nth-child(3) {
					margin-bottom: 3px; }

				&:nth-child(4) {
					margin-bottom: 15px; }

				h4, p {
					font-size: 16px;
					line-height: 1.75; }

				h4 {
					width: 98px; } }

			.content {
				p {
					font-size: 16px;
					line-height: 1.75; } } }

		.design {
			margin-top: 36px; } } }
